import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Location } from '@portals/icons';
import { getFixedT } from 'i18next';
import { find, get, map } from 'lodash-es';
import React from 'react';

import { CustomizeSearchButton } from '../../../components';
import { Criteria, FilterConfigFormat } from '../../../config';
import { useLocationFormatter, usePriceFormatter, useRoomFormatter, useSquareMetersFormatter } from '../../../hooks';
import { Area, CurrencyEuro, PropertySize, Room } from '../../../icons';
import { type FilterValuesProps } from '../../../types';
import { FilterConfigCriteria, getFilterConfig } from '../../../utils/estateTypeIdUtils';
import { type ShowResultFilterDrawerType } from '../../EstateResult/useFilterDrawer/useFilterDrawer';
import { ReadonlyFilterInput } from '../ReadonlyFilterInput/ReadonlyFilterInput';

const filterConfig: FilterConfigCriteria = getFilterConfig(FilterConfigFormat.Criteria) as FilterConfigCriteria;

interface EstateResultFilterViewProps {
  filterValues: FilterValuesProps;
  numberOfActiveFilters: number;
  showResultFilterDrawer: ShowResultFilterDrawerType;
}

const t = getFixedT(null, 'core-immobilien');

export const EstateResultFilterView: React.FC<EstateResultFilterViewProps> = ({
  filterValues,
  numberOfActiveFilters,
  showResultFilterDrawer,
}: EstateResultFilterViewProps) => {
  const priceFormatter = usePriceFormatter();
  const squareMetersFormatter = useSquareMetersFormatter();
  const roomFormatter = useRoomFormatter();
  const locationFormatter = useLocationFormatter();

  const renderDynamicFilterInputs = () => {
    const singleFilterConfig = find(filterConfig, {
      marketingType: filterValues.marketingType,
      estateTypeId: filterValues.estateTypeId,
    });
    if (get(singleFilterConfig, 'fields', []).length === 0) {
      return null;
    }

    return map(singleFilterConfig.fields, (field) => {
      switch (field) {
        case Criteria.PRICE:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={CurrencyEuro}
                label={t('price')}
                value={priceFormatter(filterValues.minPrice, filterValues.maxPrice, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.PRICE)}
              />
            </Grid>
          );
        case Criteria.MARKET_VALUE:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={CurrencyEuro}
                label={t('price')}
                value={priceFormatter(filterValues.minMarketValue, filterValues.maxMarketValue, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.MARKET_VALUE)}
              />
            </Grid>
          );
        case Criteria.RENT:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={CurrencyEuro}
                label={t('price')}
                value={priceFormatter(filterValues.minRent, filterValues.maxRent, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.RENT)}
              />
            </Grid>
          );
        case Criteria.LEASE:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={CurrencyEuro}
                label={t('price')}
                value={priceFormatter(filterValues.minLease, filterValues.maxLease, t('price'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.LEASE)}
              />
            </Grid>
          );
        case Criteria.LIVING_SPACE:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={Area}
                label={t('livingSpace')}
                value={squareMetersFormatter(
                  filterValues.minLivingSpace,
                  filterValues.maxLivingSpace,
                  t('livingSpace')
                )}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.LIVING_SPACE)}
              />
            </Grid>
          );
        case Criteria.PROPERTY_SIZE:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={PropertySize}
                label={t('propertySize')}
                value={squareMetersFormatter(
                  filterValues.minPropertySize,
                  filterValues.maxPropertySize,
                  t('propertySize')
                )}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.PROPERTY_SIZE)}
              />
            </Grid>
          );
        case Criteria.TOTAL_SPACE:
          return (
            <Grid size="grow" key={field}>
              <ReadonlyFilterInput
                Icon={Area}
                label={t('totalSpace')}
                value={squareMetersFormatter(filterValues.minTotalSpace, filterValues.maxTotalSpace, t('totalSpace'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.TOTAL_SPACE)}
              />
            </Grid>
          );
        case Criteria.ROOMS:
          return (
            <Grid size="grow" key={field} sx={{ maxWidth: { md: '140px', lg: '180px' } }}>
              <ReadonlyFilterInput
                Icon={Room}
                label={t('room')}
                value={roomFormatter(filterValues.minNumberRooms, filterValues.maxNumberRooms, t('room'))}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => showResultFilterDrawer(false, Criteria.ROOMS)}
              />
            </Grid>
          );
        default:
          return null;
      }
    });
  };
  return (
    <Box
      sx={{
        display: 'block',
        width: '100%',
      }}
    >
      <Box sx={{ pt: 6, pb: 5 }}>
        <Grid container columnSpacing={{ md: 3, lg: 5 }}>
          <Grid size="grow">
            <ReadonlyFilterInput
              Icon={Location}
              label={t('where')}
              value={locationFormatter(filterValues.zipCityEstateId, t('where'))}
              onClick={() => showResultFilterDrawer(false, Criteria.ZIP_CITY_ESTATE_ID)}
            />
          </Grid>
          {renderDynamicFilterInputs()}
          <Grid size="auto">
            <CustomizeSearchButton
              numberOfActiveFilters={numberOfActiveFilters}
              onClick={() => {
                showResultFilterDrawer(true, null);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: { lg: 4 } }} />
    </Box>
  );
};

EstateResultFilterView.displayName = 'EstateResultFilterView';
