import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  imageSection: { height: 1, cursor: 'pointer' },
  previewSection: { height: 1 },
  detailImageOuterContainer: {
    '@media print': { flexBasis: '50%', maxWidth: '50%' },
  },
  previewImageOuterContainer: {
    display: {
      xs: 'none',
      lg: 'inherit',
    },
    '@media print': { display: 'inherit', flexBasis: '50%', maxWidth: '50%' },
  },
  estateImageContainer: {
    height: 1,
    width: 1,
    bgcolor: theme.palette.grey[300],
    position: 'relative',
    aspectRatio: '3/2',
  },
  estateImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});
