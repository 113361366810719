import { Button } from '@portals/core/src/components/Button/Button';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import { getFixedT } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { EstateFilterTypes } from '../../../config';
import { ImmobilienApiSwitchContext } from '../../../context';
import { Logger } from '../../../utils';
import { debounce } from '../../../utils/debounce';
export interface Props {
  disabled: boolean;
}
const t = getFixedT(null, 'core-immobilien');

export const HitButton = (props: Props): React.ReactElement => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch and context
  const { useImmobilienApi } = useContext(ImmobilienApiSwitchContext);

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const { values, errors } = useFormikContext<EstateSearchProps>();
  const { disabled } = props;
  const prevValuesRef = useRef(values);

  const fetchHits = (values, errors) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    setLoading(true);
    ApiClientProvider.getApiClient()
      .getEstateCount(values as EstateSearchProps)
      .then(({ totalItems }) => {
        setCount(totalItems);
      })
      .catch((e) => {
        setCount(0);
        Logger.warn(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceFetch = useRef(debounce(fetchHits, 1000)).current;

  const debounceFilterFields = new Set([
    EstateFilterTypes.MAX_PRICE,
    EstateFilterTypes.MAX_RENT,
    EstateFilterTypes.MAX_MARKET_VALUE,
    EstateFilterTypes.MAX_LEASE,
    EstateFilterTypes.MIN_LIVING_SPACE,
    EstateFilterTypes.MIN_PROPERTY_SIZE,
    EstateFilterTypes.MIN_TOTAL_SPACE,
  ]);

  useEffect(() => {
    const prevValues = prevValuesRef.current;
    const changedFields = Object.keys(values).filter((key) => values[key] !== prevValues[key]);
    if (!disabled && (initialLoading || changedFields.length > 0)) {
      if (useImmobilienApi && changedFields.some((field: EstateFilterTypes) => debounceFilterFields.has(field))) {
        debounceFetch(values, errors);
      } else {
        fetchHits(values, errors);
      }
    }
    prevValuesRef.current = values;
    setInitialLoading(false);
  }, [values, errors]);

  return (
    <Button
      data-testid="estateSearchHitButton"
      loading={loading}
      disabled={disabled}
      variant="standard"
      color="primary"
      type="submit"
      fullWidth
      sx={{ height: { xs: '45px', lg: '62px' } }}
    >
      {t('showResults', { count: !disabled ? count : 0 })}
    </Button>
  );
};

HitButton.displayName = 'HitButton';
