import { Box } from '@mui/material';
import { options } from '@portals/core/src/themes/sde/main';
import React from 'react';

type BoxProps = React.ComponentProps<typeof Box> & {
  disableDefaultPadding?: boolean;
};

const DEFAULT_PADDING = { xs: 5, lg: 6 };
const DEFAULT_MAX_CHILD_WIDTH = 800;

const calculateMaxBoxWidth = (size: keyof typeof DEFAULT_PADDING) => {
  return DEFAULT_MAX_CHILD_WIDTH + 2 * options.spacing[DEFAULT_PADDING[size]];
};

const DEFAULT_MAX_BOX_WIDTH = {
  xs: calculateMaxBoxWidth('xs'),
  lg: calculateMaxBoxWidth('lg'),
};

export const CenteredContainer = ({ disableDefaultPadding = false, children, sx, ...rest }: BoxProps): JSX.Element => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Box
      sx={{
        width: '100%',
        maxWidth: DEFAULT_MAX_BOX_WIDTH,
        paddingX: disableDefaultPadding ? 0 : DEFAULT_PADDING,
        '@media print': {
          maxWidth: '130mm',
          width: '130mm',
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  </Box>
);

CenteredContainer.displayName = 'CenteredContainer';
