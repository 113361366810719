import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Avatar } from '@portals/core/src/components/Avatar/Avatar';
import { Button } from '@portals/core/src/components/Button/Button';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { Location, SparkasseLogo } from '@portals/icons';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { isEqual } from 'lodash-es';
import React, { FunctionComponent } from 'react';

import { CenteredContainer } from '../../../../../components';
import { useIF6Context } from '../../../../../context';
import { useFetchLoansData } from '../../../../../hooks';
import { FinanceData } from '../../../../../types';
import { getStyles } from '../FinanceBlock.styles';
import FinanceConditions from '../FinanceConditions/FinanceConditions';

export interface FinancingDetailsProps {
  estate: EstateDetail;
  bankCode: string;
  bankName: string;
  financeData: FinanceData;
  onChooseSparkasseClick: () => void;
  handleNoFinancingData: () => void;
}

const propsAreEqual = (prevProps: FinancingDetailsProps, nextProps: FinancingDetailsProps) => {
  return isEqual(prevProps, nextProps);
};

const t = getFixedT(null, 'core-immobilien');

const FinancingDetails: FunctionComponent<FinancingDetailsProps> = ({
  estate,
  bankCode,
  bankName,
  financeData,
  onChooseSparkasseClick,
  handleNoFinancingData,
}) => {
  const styles = getStyles(theme).financingDetails;
  const isIF6Scope = useIF6Context();

  const { loansData, isFetchingLoansData } = useFetchLoansData({
    estate,
    bankCode,
    financeData,
    handleNoFinancingData,
  });

  const financingConditionsGiven = !!loansData?.loansConditions?.tenYears;

  return (
    <Box sx={styles.container}>
      <CenteredContainer>
        <Grid container sx={styles.gridContainer}>
          <Grid size={{ xs: 12, lg: 6 }}>
            {!isIF6Scope && (
              <Grid container sx={styles.nonIF6Container}>
                <Grid>
                  <Avatar sx={styles.gridItemAvatar}>
                    <SparkasseLogo sx={styles.sparkasseLogo} />
                  </Avatar>
                </Grid>
                <Grid>
                  <Typography variant="h5">{bankName}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {!isIF6Scope && (
            <Grid size={{ xs: 12, lg: 4 }} sx={styles.isIF6Container}>
              <Typography variant="button" sx={styles.buttonWrapper}>
                <Button
                  fullWidth={true}
                  onClick={onChooseSparkasseClick}
                  color="secondary"
                  sx={styles.button}
                  endIcon={<Location fontSize="medium" />}
                >
                  {t('detailPage.changeBankButtonLabel')}
                </Button>
              </Typography>
            </Grid>
          )}
        </Grid>

        {isFetchingLoansData && (
          <Box sx={styles.loadingBox}>
            <LoadingDots />
          </Box>
        )}

        {!isFetchingLoansData && !financingConditionsGiven && (
          <Box sx={styles.noFinancingBox}>
            <Divider />
            <Typography variant="body2" sx={styles.noFinancingText}>
              {t('detailPage.noFinancingConditionsText', { bankName })}
            </Typography>
            {loansData?.calculateConditionsURL && (
              <Box>
                <Button
                  href={loansData.calculateConditionsURL}
                  color="secondary"
                  size="small"
                  sx={styles.calculateButton}
                >
                  {t('detailPage.calculateConditionsButtonLabel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </CenteredContainer>
      {!isFetchingLoansData && financingConditionsGiven && <FinanceConditions estate={estate} loansData={loansData} />}
    </Box>
  );
};

FinancingDetails.displayName = 'FinancingDetails';

export default React.memo(FinancingDetails, propsAreEqual);
