import { Box } from '@mui/material';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { theme } from '@portals/core/src/themes/sde/main';
import type { EstateEyecatcher, EstateMainFact } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import React, { useMemo } from 'react';

import { Badges, ImageContainer, MainFacts } from '..';
import { Cost } from './Cost/Cost';
import { getStyles } from './EstateCardItem.styles';
import { SectionWrapper } from './SectionWrapper/SectionWrapper';
import { Subtitle } from './Subtitle/Subtitle';
import { Title } from './Title/Title';

export type EstateCardProps = {
  title: string;
  subtitle: string;
  images: string[];
  priceData: EstateMainFact;
  eyecatcher?: EstateEyecatcher[];
  mainFacts: EstateMainFact[];
  partnerLogo?: string;
  isRentObject?: boolean;
  bookmarkButton?: React.ReactNode;
};

const t = getFixedT(null, 'core-immobilien');

export const EstateCardItem: React.FC<EstateCardProps> = ({
  title,
  subtitle,
  images,
  priceData,
  eyecatcher = [],
  mainFacts,
  partnerLogo,
  isRentObject,
  bookmarkButton,
}) => {
  const styles = useMemo(() => getStyles(theme, priceData, eyecatcher), [priceData, eyecatcher]);

  const getPaymentCycle = (): string => {
    const rentLabel = t('estateList.substring_rentObject');
    const isRentLabel = priceData?.label.includes(rentLabel);

    if (!priceData || (!isRentObject && !isRentLabel) || priceData?.value === 'auf Anfrage') {
      return '';
    }

    return t('estateCard.paymentCycle.monthly');
  };

  return (
    <Box sx={styles.outerContainer}>
      <SectionWrapper data-testid="image-section" disableInnerPadding>
        <ImageContainer title={title} imageList={images} partnerLogo={partnerLogo} bookmarkButton={bookmarkButton} />
      </SectionWrapper>

      <SectionWrapper data-testid="title-section">
        <Subtitle subtitle={subtitle} />
        <Title title={title} />
      </SectionWrapper>

      <SectionWrapper data-testid="mainfacts-section" isColumnFlexBox sx={styles.mainFactsContainer}>
        <MainFacts list={mainFacts} />
      </SectionWrapper>

      {eyecatcher.length > 0 && (
        <SectionWrapper data-testid="eyecatcher-section" isColumnFlexBox sx={styles.badgesContainer}>
          <Badges badgeItems={eyecatcher} />
        </SectionWrapper>
      )}

      <SectionWrapper data-testid="divider-section" disableInnerPadding sx={styles.dividerContainer}>
        <Divider />
      </SectionWrapper>

      <SectionWrapper data-testid="cost-section" isRowFlexBox>
        <Cost costType={priceData?.label} priceWithCurrency={priceData?.value} paymentCycle={getPaymentCycle()} />
      </SectionWrapper>
    </Box>
  );
};

EstateCardItem.displayName = 'EstateCardItem';
