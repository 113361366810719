import { getFixedT } from 'i18next';
import React from 'react';

import { NotFoundBox } from './NotFoundBox/NotFoundBox';

export type EstateNotFoundProps = {
  className?: string;
};

const t = getFixedT(null, 'core-immobilien');

export const EstateNotFound = ({ className }: EstateNotFoundProps): JSX.Element => {
  return (
    <NotFoundBox
      headline={t('detailPage.estateNotFoundHeadline')}
      subline={t('detailPage.estateNotFoundSubline')}
      sx={{ py: { xs: 8, lg: 9 } }}
      className={className}
    />
  );
};

EstateNotFound.displayName = 'EstateNotFound';
