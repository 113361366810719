import type { SxProps, Theme } from '@portals/core/src/themes/themes';
import type { EstateEyecatcher, EstateMainFact } from '@portals/sip-client-data/src/general/ApiClientTypes';

export const getStyles = (
  theme: Theme,
  priceData?: EstateMainFact,
  eyecatcher?: EstateEyecatcher[]
): Record<string, SxProps> => ({
  outerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    gap: { xs: theme.spacing(5), lg: theme.spacing(6) },
  },
  mainFactsContainer: {
    rowGap: theme.spacing(4),
    pt: { xs: theme.spacing(2), lg: 0 },
  },
  badgesContainer: { flexWrap: 'wrap', gap: { xs: theme.spacing(2), lg: theme.spacing(4) }, mb: 'auto' },
  dividerContainer: {
    width: '100%',
    display: typeof priceData?.value === 'undefined' ? 'none' : 'unset',
    ...(eyecatcher?.length === 0 ? { marginTop: 'auto' } : null),
  },
});
