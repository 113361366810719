import { Box, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@portals/icons';
import { ImprintData } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import React from 'react';

import { if6CssPrefix } from '../../../../config';
import { useIF6Context } from '../../../../context';
import ConsumerInformation from './ConsumerInformation/ConsumerInformation';
import Imprint from './Imprint/Imprint';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';

export type LegalDialogTypes = 'imprint' | 'privacyPolicy' | 'consumerInformation' | 'contact';

export interface EstateLegalModalProps {
  open: boolean;
  onClose: () => void;
  dialogType: LegalDialogTypes;
  content?: string | ImprintData;
  children?: React.ReactNode;
}

const t = getFixedT(null, 'core-immobilien');

export const EstateLegalModal: React.FunctionComponent<EstateLegalModalProps> = ({
  open,
  onClose,
  dialogType,
  content,
  children,
}: EstateLegalModalProps) => {
  const isIF6Scope = useIF6Context();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getParentContainer = () => {
    if (isIF6Scope && typeof window !== 'undefined') {
      return document.getElementsByClassName(if6CssPrefix)[0];
    }
    return undefined;
  };

  return (
    <Dialog
      container={getParentContainer()}
      className="legal-modal-container"
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      scroll="paper"
      PaperProps={{
        className: 'legal-modal-container-paper ',
        style: {
          borderRadius: isMobile ? 0 : '12px',
          maxWidth: '1020px',
          width: '100%',
        },
      }}
    >
      <DialogTitle
        variant="h3"
        component="h3"
        color="black"
        sx={{ padding: 0, marginTop: 0, marginBottom: theme.spacing(7), marginX: theme.spacing(6) }}
        className="legal-modal-title"
      >
        <Box sx={{ textAlign: 'right' }}>
          <IconButton onClick={onClose} sx={{ color: (theme) => theme.palette.grey[500] }}>
            <Close color="inherit" />
          </IconButton>
        </Box>
        {dialogType === 'imprint' && t('detailPage.imprintHeadline')}
        {dialogType === 'privacyPolicy' && t('detailPage.privacyPolicyHeadline')}
        {dialogType === 'consumerInformation' && t('detailPage.consumerInformationHeadline')}
        {dialogType === 'contact' && t('detailPage.contractProvider')}
      </DialogTitle>

      <DialogContent sx={{ paddingX: 6, marginBottom: 7 }}>
        {dialogType === 'imprint' && <Imprint data={content as ImprintData} />}
        {dialogType === 'privacyPolicy' && <PrivacyPolicy text={content.toString()} />}
        {dialogType === 'consumerInformation' && <ConsumerInformation text={content.toString()} />}
        {children}
      </DialogContent>
    </Dialog>
  );
};

EstateLegalModal.displayName = 'EstateLegalModal';
