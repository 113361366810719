import { Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';

import { getStyles } from './FilterAndResultControlView.styles';

export const FilterAndResultControlViewSkeletonDesktop = () => {
  const styles = getStyles();
  return (
    <>
      <Box sx={styles.headerContainer}>
        <Skeleton variant="rounded" sx={styles.headlineSkeleton} />
      </Box>
      <Box sx={styles.estateResultFilterViewContainer}>
        <Grid container spacing={5}>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Skeleton variant="rounded" sx={styles.filterInputSkeleton} />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Skeleton variant="rounded" sx={styles.filterInputSkeleton} />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Skeleton variant="rounded" sx={styles.filterInputSkeleton} />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Skeleton variant="rounded" sx={styles.filterButtonSkeleton} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const FilterAndResultControlViewSkeletonMobile = () => {
  const styles = getStyles();
  return (
    <>
      <Grid size={12} sx={styles.mobileSearchResultHeadline}>
        <Box
          className="estate-list-headline-container"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', lg: 'row' },
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', order: { xs: 1, lg: 2 } }}>
            <Box sx={{ padding: '6px 16px' }}>
              <Skeleton variant="rounded" sx={{ width: 160, height: 31, marginTop: 2 }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              mt: { xs: 0, sm: 3, md: 0 },
              mb: 0,
              order: { xs: 2, lg: 1 },
            }}
          >
            <Box
              className="estate-list-headline"
              sx={{
                display: 'flex',
                alignItems: { lg: 'center' },
                pl: {
                  xs: 0,
                  text: 6,
                },
                pr: 2,
              }}
            >
              <Skeleton variant="rounded" sx={styles.headlineSkeleton} />
            </Box>
            <Skeleton variant="rounded" sx={styles.filterButtonSkeleton} />
          </Box>
        </Box>
      </Grid>
      <Grid size={{ xs: 12, sm: 8, md: 7 }} />
    </>
  );
};
