import { alpha, Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, Record<string, SxProps<Theme>>> => ({
  financeBlock: {
    container: { displayPrint: 'none' },
    assigmentContainer: { display: 'flex', flexDirection: 'column', gap: 8 },
    paper: {
      py: { xs: 8, lg: 9 },
      px: { xs: 5, lg: 6 },
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 1,
      boxShadow: `0px 2px 3px ${alpha(theme.palette.secondary['main'], 0.1)}`,
    },
  },

  financingDetails: {
    container: {
      gap: 8,
      display: 'flex',
      flexDirection: 'column',
    },
    gridContainer: {
      justifyContent: 'space-between',
      columnGap: 11,
      rowGap: 4,
      wrap: { xs: 'wrap', lg: 'nowrap' },
    },
    nonIF6Container: { gap: 5, alignItems: 'center', wrap: 'nowrap' },
    isIF6Container: { display: 'flex', alignItems: 'center' },
    gridItemAvatar: {
      height: { xs: theme.spacing(10), lg: theme.spacing(12) },
      width: { xs: theme.spacing(10), lg: theme.spacing(12) },
      backgroundColor: theme.palette.primary.main,
    },
    sparkasseLogo: {
      fontSize: { xs: '42px', lg: '60px' },
    },
    buttonWrapper: { display: 'flex', width: '100%' },
    button: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
    loadingBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    noFinancingBox: {
      gap: 5,
      display: 'flex',
      flexDirection: 'column',
    },
    noFinancingText: {
      color: 'grey.600',
    },
    calculateButton: {
      width: { xs: '100%', lg: 'inherit' },
    },
  },
});
