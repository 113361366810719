import { GalleryItems } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { head } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

import { GalleryFilterType } from '../GalleryButton/GalleryButton';
import {
  GalleryCarouselItem,
  GalleryCarouselThreeSixtyItem,
  GalleryCarouselVideoItem,
} from '../GalleryCarousel/GalleryCarouselItem';
import { GalleryModal } from '../GalleryModal/GalleryModal';

export interface Props {
  items: Array<React.ReactNode>;
  galleryOpen: boolean;
  onClose: () => void;
  currentImagePosition: number | null;
}

const t = getFixedT(null, 'core-immobilien');

export const Gallery = ({ items, galleryOpen, onClose, currentImagePosition }: Props): React.ReactElement => {
  const [itemsToRender, setItemsToRender] = useState<React.ReactNode[]>([]);
  const handleGalleryClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const getImageBySize = (image: GalleryItems, imageSize: string) => {
    const originalImage = image.resources.filter((resource) => resource.size === imageSize);
    return head(originalImage)?.url;
  };

  const getGalleryItems = useCallback((filteredItems) => {
    const generateGalleryCarouselItems = (imgUrl, description) => {
      return <GalleryCarouselItem description={description} url={imgUrl} key={`${imgUrl}__${description}`} />;
    };

    const generateGalleryCarouselThreeSixtyItems = (threeSixtyUrl) => {
      return (
        <GalleryCarouselThreeSixtyItem
          url={threeSixtyUrl}
          description={t('gallery.threeSixty')}
          key={`${threeSixtyUrl}`}
        />
      );
    };

    const generateGalleryCarouselVideoItems = (videoUrl, description) => {
      return <GalleryCarouselVideoItem url={videoUrl} description={description} key={`${videoUrl}`} />;
    };

    return filteredItems.map((item) => {
      switch (item.type) {
        case GalleryFilterType.FLOORPLAN:
          return generateGalleryCarouselItems(getImageBySize(item, 'xl'), item.description);
        case GalleryFilterType.THREE_SIXTY:
          return generateGalleryCarouselThreeSixtyItems(head(item.resources)?.url);
        case GalleryFilterType.VIDEO:
          return generateGalleryCarouselVideoItems(head(item.resources)?.url, item.description);
        default:
          return generateGalleryCarouselItems(getImageBySize(item, 'xl'), item.description);
      }
    });
  }, []);

  useEffect(() => {
    setItemsToRender(getGalleryItems(items));
  }, [getGalleryItems, items]);

  return (
    <GalleryModal
      currentImagePosition={currentImagePosition}
      open={galleryOpen}
      onClose={handleGalleryClose}
      items={itemsToRender}
    />
  );
};

Gallery.displayName = 'Gallery';
