import { mapZipCityEstateIdToCollection } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import { getFixedT } from 'i18next';
import { useCallback } from 'react';

export interface LocationProps {
  zipCityEstateId: string;
}

export type formatterFunction = (zipCityEstateId: string | null, defaultLabel?: string) => string;

const t = getFixedT(null, 'core-immobilien');

export function useLocationFormatter(): formatterFunction {
  return useCallback((zipCityEstateId, defaultLabel) => {
    const values = mapZipCityEstateIdToCollection(zipCityEstateId);
    if (values === null || values.length === 0) {
      return defaultLabel ? defaultLabel : '';
    }

    if (values.length === 1) {
      return values[0].label;
    }

    return t('moreThenOneLocation', { label: values[0].label, count: values.length - 1 });
  }, []);
}
