import { FinalContainer, FinalGridContainer, FinalGridItem } from '@portals/core/src/components/FinalGrid/FinalGrid';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React from 'react';

import { EstateResultMapView } from '../../EstateResultFilterView/EstateResultMapView/EstateResultMapView';

interface MapViewProps {
  estates?: Array<EstateItem>;
}

export const MapView = ({ estates }: MapViewProps): React.ReactElement => {
  return (
    <FinalContainer
      disableGutters
      sx={{
        flexGrow: 1,
        display: 'flex',
        zIndex: 0,
      }}
    >
      <FinalGridContainer sx={{ width: '100%' }}>
        <FinalGridItem md={12} lg={12}>
          <EstateResultMapView estates={estates} />
        </FinalGridItem>
      </FinalGridContainer>
    </FinalContainer>
  );
};

MapView.displayName = 'MapView';
