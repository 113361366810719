import { Box } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { Filter } from '@portals/icons';
import { getFixedT } from 'i18next';
import React from 'react';

type ButtonProps = React.ComponentProps<typeof Button>;

export interface Props extends ButtonProps {
  numberOfActiveFilters: number;
  onClick: ButtonProps['onClick'];
}

const t = getFixedT(null, 'core-immobilien');

export const CustomizeSearchButton: React.FunctionComponent<Props> = ({
  numberOfActiveFilters,
  onClick,
  ...props
}: Props) => {
  return (
    <Button
      color="secondary"
      startIcon={
        <Box
          sx={{
            background: theme.palette.secondary.darker,
            borderRadius: '50%',
            width: { xs: 20, lg: 24 },
            height: { xs: 20, lg: 24 },
            color: theme.palette.secondary.contrastText,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: { xs: 15, lg: 18 } }}>{numberOfActiveFilters}</Typography>
        </Box>
      }
      endIcon={<Filter />}
      onClick={onClick}
      sx={{
        whiteSpace: 'nowrap',
      }}
      {...props}
    >
      <Box sx={{ display: 'inherit' }}>{t('customizeSearch')}</Box>
    </Button>
  );
};

CustomizeSearchButton.displayName = 'CustomizeSearchButton';
