import { GalleryItems } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { filter, uniqBy } from 'lodash-es';
import React, { useCallback } from 'react';

import { GalleryButton, GalleryFilterType } from './GalleryButton';

export interface Props {
  onClick: (filter: GalleryFilterType | null, galleryPosition: number | null) => void;
  items: GalleryItems[];
}

const t = getFixedT(null, 'core-immobilien');

export const GalleryButtons = ({ onClick, items }: Props): React.ReactElement => {
  const handleOnGalleryButtonClick = useCallback(
    (filterType) => (event) => {
      event.currentTarget.blur();
      onClick(filterType, null);
    },
    [onClick]
  );

  const renderGalleryButtons = () => {
    const distinctImageList = uniqBy(items, (entry) => entry.type);
    const imageCount = filter(items, { type: GalleryFilterType.IMAGE }).length;
    const floorplanCount = filter(items, { type: GalleryFilterType.FLOORPLAN }).length;

    return distinctImageList.map((image, index) => {
      switch (image.type) {
        case GalleryFilterType.IMAGE:
          return (
            <GalleryButton
              key={`${image.description}-${index}`}
              onClick={handleOnGalleryButtonClick(GalleryFilterType.IMAGE)}
              type={GalleryFilterType.IMAGE}
              label={t('gallery.showAllPictures', { count: imageCount })}
            ></GalleryButton>
          );
        case GalleryFilterType.THREE_SIXTY:
          return (
            <GalleryButton
              key={`${image.description}-${index}`}
              onClick={handleOnGalleryButtonClick(GalleryFilterType.THREE_SIXTY)}
              type={GalleryFilterType.THREE_SIXTY}
              label={t('gallery.threeSixty')}
            ></GalleryButton>
          );
        case GalleryFilterType.FLOORPLAN:
          return (
            <GalleryButton
              key={`${image.description}-${index}`}
              onClick={handleOnGalleryButtonClick(GalleryFilterType.FLOORPLAN)}
              type={GalleryFilterType.FLOORPLAN}
              label={t('gallery.floorplan', { count: floorplanCount })}
            ></GalleryButton>
          );
        case GalleryFilterType.VIDEO:
          return (
            <GalleryButton
              key={`${image.description}-${index}`}
              onClick={handleOnGalleryButtonClick(GalleryFilterType.VIDEO)}
              type={GalleryFilterType.VIDEO}
              label={t('gallery.video')}
            ></GalleryButton>
          );
        default:
          return null;
      }
    });
  };

  return renderGalleryButtons();
};

GalleryButtons.displayName = 'GalleryButtons';
