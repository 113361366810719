import { SvgIconProps, SxProps } from '@mui/material';
import { Check } from '@portals/icons';
import { getFixedT } from 'i18next';
import React, { useCallback } from 'react';

import { FilterPill, HorizontalSwipeBarStyles as styles } from '../../../../../../components';
import { POIControlPanelType } from '../../../../../../config';
import { Asklepios, BoyCap, CookingHut, Shop } from '../../../../../../icons';

interface POIControlPanelButtonProps {
  group: { label: string };
  index: number;
  selectedIndex: number;
  onSelect: (index: number) => void;
}

export const renderIcon = (type: string): React.FC<SvgIconProps> | null => {
  switch (type) {
    case POIControlPanelType.SUPERMARKETS:
      return Shop;
    case POIControlPanelType.PHARMACY:
      return Asklepios;
    case POIControlPanelType.CHILD_DAYCARE:
      return BoyCap;
    case POIControlPanelType.RESTAURANTS:
      return CookingHut;
    default:
      return null;
  }
};

const POIControlPanelButtonIcon = ({ label }: { label: string }) => {
  const Icon = renderIcon(label);
  return Icon ? <Icon /> : null;
};

const t = getFixedT(null, 'core-immobilien');

export const POIControlPanelButton: React.FC<POIControlPanelButtonProps> = ({
  group,
  index,
  selectedIndex,
  onSelect,
}) => {
  const handleSelect = useCallback(() => onSelect(index), [index, onSelect]);

  return (
    <FilterPill
      sx={{ ...(index === selectedIndex ? styles.buttonSelected : {}) } as SxProps}
      avatar={group.label && (index === selectedIndex ? <Check /> : <POIControlPanelButtonIcon label={group.label} />)}
      label={t(group.label)}
      onClick={handleSelect}
      onFocus={handleSelect}
    />
  );
};
