import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { ChevronDownSmall } from '@portals/icons/src/ChevronDownSmall/ChevronDownSmall';
import { ChevronUpSmall } from '@portals/icons/src/ChevronUpSmall/ChevronUpSmall';
import { getFixedT } from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ToggleTextLink } from './ToggleTextLink';

export interface Props {
  content?: string;
}

const t = getFixedT(null, 'core-immobilien');

export const ContentText: React.FunctionComponent<Props> = ({ content }: Props) => {
  const [shouldTextBeTruncated, setShouldTextBeTruncated] = useState(true);
  const [isTruncatedWithEllipsis, setIsTruncatedWithEllipsis] = useState(false);
  const textContainerRef = useRef<HTMLDivElement>();

  const cleanContent = content?.replace(/<br(.*?)\/>/gi, '<br/>') ?? '';

  const togglePreview = useCallback(() => {
    setShouldTextBeTruncated((prevState) => {
      return !prevState;
    });
  }, []);

  const showMoreLink = (
    <ToggleTextLink text={t('detailPage.showMore')} Icon={ChevronDownSmall} handleOnClick={togglePreview} />
  );
  const showLessLink = (
    <ToggleTextLink text={t('detailPage.showLess')} Icon={ChevronUpSmall} handleOnClick={togglePreview} />
  );

  const renderToggleLink = () => {
    if (shouldTextBeTruncated) {
      return showMoreLink;
    }
    return showLessLink;
  };

  useEffect(() => {
    if (!textContainerRef.current) {
      return;
    }
    const { offsetHeight, scrollHeight } = textContainerRef.current;
    const isTruncated = offsetHeight < scrollHeight;
    setIsTruncatedWithEllipsis(isTruncated);
  }, []);

  return (
    <Grid container>
      <Grid size={12}>
        <Typography className="content-text" variant="body2">
          <Box
            ref={textContainerRef}
            sx={{
              ...(shouldTextBeTruncated && {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: { xs: '7', md: '10' },
                WebkitBoxOrient: 'vertical',
              }),
              '@media print': {
                display: 'block',
                lineHeight: 1.75,
                fontSize: '13px',
                orphans: 3,
                widows: 3,
              },
            }}
            dangerouslySetInnerHTML={{ __html: cleanContent }}
          ></Box>
        </Typography>

        {isTruncatedWithEllipsis && <Box sx={{ displayPrint: 'none', mt: { xs: 5, lg: 6 } }}>{renderToggleLink()}</Box>}
      </Grid>
    </Grid>
  );
};

ContentText.displayName = 'ContentText';
