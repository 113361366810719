import { SvgIconProps, useTheme } from '@mui/material';
import { Link } from '@portals/core/src/components/Link/Link';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import React, { FC } from 'react';

type ToggleTextLinkProps = {
  text: string;
  Icon: FC<SvgIconProps>;
  handleOnClick: () => void;
};

export const ToggleTextLink: React.FunctionComponent<ToggleTextLinkProps> = ({
  text,
  Icon,
  handleOnClick,
}: ToggleTextLinkProps) => {
  const theme = useTheme<Theme>();
  return (
    <Link
      onClick={handleOnClick}
      underline="none"
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
      }}
    >
      <Typography variant="button" component="div" sx={{ fontWeight: 'bold' }}>
        {text}
      </Typography>
      <Icon sx={{ ml: 2 }} />
    </Link>
  );
};

ToggleTextLink.displayName = 'ToggleTextLink';
