import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import React from 'react';

import { FilterTextField } from '../FilterTextField/FilterTextField';

interface Props {
  headline: string;
  labelFrom: string;
  fieldNameFrom: string;
  labelTo: string;
  fieldNameTo: string;
  replaceRegex?: RegExp;
}

export const FromToFilter: React.FunctionComponent<Props> = ({
  headline,
  labelFrom,
  fieldNameFrom,
  labelTo,
  fieldNameTo,
  replaceRegex = undefined,
}: Props) => (
  <Box sx={{ px: 6 }}>
    <Typography variant="h3">{headline}</Typography>
    <Grid container columnSpacing={5}>
      <Grid size={6}>
        <FilterTextField label={labelFrom} fieldName={fieldNameFrom} replaceRegex={replaceRegex} />
      </Grid>
      <Grid size={6}>
        <FilterTextField label={labelTo} fieldName={fieldNameTo} replaceRegex={replaceRegex} />
      </Grid>
    </Grid>
  </Box>
);

FromToFilter.displayName = 'FromToFilter';
