import { Box, Fab, FabProps, useScrollTrigger, Zoom } from '@mui/material';
// todo: wie löst man den import evtl. Mui Update?
// eslint-disable-next-line no-restricted-imports
import { UseScrollTriggerOptions } from '@mui/material/useScrollTrigger/useScrollTrigger';
import { merge } from 'lodash-es';
import React from 'react';

export interface Props {
  onClick: FabProps['onClick'];
  triggerOptions?: UseScrollTriggerOptions;
  children: React.ReactElement | React.ReactElement[];
}

export const FloatingButton: React.FunctionComponent<Props> = ({ onClick, triggerOptions, children }: Props) => {
  const mergedConfig = merge(
    {},
    {
      target: undefined,
      disableHysteresis: true,
      threshold: 100,
    },
    triggerOptions
  );

  const trigger = useScrollTrigger(mergedConfig);

  return (
    <Zoom in={trigger}>
      <Box
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: 1,
          zIndex: 2,
          left: 0,
          right: 0,
          pointerEvents: 'none',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 5 }}>
          <Fab variant="extended" color="primary" onClick={onClick}>
            {children}
          </Fab>
        </Box>
      </Box>
    </Zoom>
  );
};

FloatingButton.displayName = 'FloatingButton';
