import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CategoryLinkList } from '@portals/core/src/components/CategoryLinkList/CategoryLinkList';
import { ProductLink } from '@portals/core/src/components/ProductLink/ProductLink';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { ArrowRight, Home, Location } from '@portals/icons';
import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { indexOf } from 'lodash-es';
import React from 'react';

import { Criteria, getPerimeterLabelFromOptionsByValue } from '../../../../config';
import {
  useLocationFormatter,
  useMarketingTypeObjectTypeFormatter,
  usePriceFormatter,
  useRoomFormatter,
  useSquareMetersFormatter,
} from '../../../../hooks';
import { Area, Currency, Perimeter, PropertySize, Room } from '../../../../icons';
import { FilterValuesProps } from '../../../../types';

interface AllFiltersProps {
  filterValues: FilterValuesProps;
  changeCriteria: (criteria: Criteria) => void;
}

const t = getFixedT(null, 'core-immobilien');

export const AllFilters: React.FC<AllFiltersProps> = ({ filterValues, changeCriteria }: AllFiltersProps) => {
  const squareMetersFormatter = useSquareMetersFormatter();
  const roomFormatter = useRoomFormatter();
  const priceFormatter = usePriceFormatter();
  const locationFormatter = useLocationFormatter();
  const marketingTypeObjectTypeFormatter = useMarketingTypeObjectTypeFormatter();

  const showCriteriaFilter = (criteria) => {
    changeCriteria(criteria);
  };

  return (
    <Box sx={{ px: 6 }}>
      <Typography variant="h3">{t('customizeSearch')}</Typography>
      <Box>
        <Grid container size={12}>
          <CategoryLinkList>
            <ProductLink
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => {
                showCriteriaFilter(Criteria.ZIP_CITY_ESTATE_ID);
              }}
              startIcon={<Location />}
            >
              {locationFormatter(filterValues.zipCityEstateId, t('where'))}
            </ProductLink>
            {filterValues.perimeter !== null && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.PERIMETER);
                }}
                startIcon={<Perimeter />}
                endIcon={<ArrowRight />}
              >
                {getPerimeterLabelFromOptionsByValue(t, filterValues.perimeter)}
              </ProductLink>
            )}
            <ProductLink
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => {
                showCriteriaFilter(Criteria.MARKETING_TYPE_OBJECT_TYPE);
              }}
              startIcon={<Home />}
            >
              {marketingTypeObjectTypeFormatter(filterValues.marketingType, filterValues.objectType, t('object'))}
            </ProductLink>
            {filterValues.marketingType === MarketingType.BUY && filterValues.objectType != ObjectType.FORECLOSURE && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.PRICE);
                }}
                startIcon={<Currency />}
              >
                {priceFormatter(filterValues.minPrice, filterValues.maxPrice, t('price'))}
              </ProductLink>
            )}
            {filterValues.marketingType === MarketingType.BUY && filterValues.objectType == ObjectType.FORECLOSURE && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.MARKET_VALUE);
                }}
                startIcon={<Currency />}
              >
                {priceFormatter(filterValues.minMarketValue, filterValues.maxMarketValue, t('price'))}
              </ProductLink>
            )}
            {filterValues.marketingType === MarketingType.RENT &&
              indexOf([ObjectType.FLAT, ObjectType.HOUSE, ObjectType.BUSINESS], filterValues.objectType) > -1 && (
                <ProductLink
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onClick={() => {
                    showCriteriaFilter(Criteria.RENT);
                  }}
                  startIcon={<Currency />}
                >
                  {priceFormatter(filterValues.minRent, filterValues.maxRent, t('price'))}
                </ProductLink>
              )}
            {filterValues.marketingType === MarketingType.RENT && filterValues.objectType == ObjectType.PROPERTY && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.LEASE);
                }}
                startIcon={<Currency />}
              >
                {priceFormatter(filterValues.minLease, filterValues.maxLease, t('price'))}
              </ProductLink>
            )}
            {indexOf([ObjectType.FLAT, ObjectType.HOUSE], filterValues.objectType) > -1 && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.LIVING_SPACE);
                }}
                startIcon={<Area />}
              >
                {squareMetersFormatter(filterValues.minLivingSpace, filterValues.maxLivingSpace, t('livingSpace'))}
              </ProductLink>
            )}
            {indexOf([ObjectType.HOUSE, ObjectType.PROPERTY], filterValues.objectType) > -1 && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.PROPERTY_SIZE);
                }}
                startIcon={<PropertySize />}
              >
                {squareMetersFormatter(filterValues.minPropertySize, filterValues.maxPropertySize, t('propertySize'))}
              </ProductLink>
            )}
            {indexOf([ObjectType.BUSINESS], filterValues.objectType) > -1 && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.TOTAL_SPACE);
                }}
                startIcon={<Area />}
              >
                {squareMetersFormatter(filterValues.minTotalSpace, filterValues.maxTotalSpace, t('totalSpace'))}
              </ProductLink>
            )}
            {indexOf([ObjectType.FLAT, ObjectType.HOUSE], filterValues.objectType) > -1 && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.ROOMS);
                }}
                startIcon={<Room />}
              >
                {roomFormatter(filterValues.minNumberRooms, filterValues.maxNumberRooms, t('room'))}
              </ProductLink>
            )}
          </CategoryLinkList>
        </Grid>
      </Box>
    </Box>
  );
};

AllFilters.displayName = 'AllFilters';
