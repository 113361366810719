import { TooltipButton } from '@portals/core/src/components/TooltipButton/TooltipButton';
import { getFixedT } from 'i18next';
import React from 'react';

import { if6CssPrefix } from '../../../../../config';

interface FinanceConditionsTooltipButtonProps {
  text: string;
}

const t = getFixedT(null, 'core-immobilien');

const FinanceConditionsTooltipButton: React.FC<FinanceConditionsTooltipButtonProps> = ({ text }) => {
  return (
    <TooltipButton
      title={t(text)}
      className={`${if6CssPrefix} finance-block-tooltip-button`}
      slotProps={{
        popper: {
          className: `${if6CssPrefix} finance-block-tooltip`,
        },
      }}
    />
  );
};

FinanceConditionsTooltipButton.displayName = 'FinanceConditionsTooltipButton';

export default FinanceConditionsTooltipButton;
