import { Box, Drawer, IconButton, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Close } from '@portals/icons';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useBookmarkContext } from '../../../../context';
import { ActionShareButton } from '../../../Buttons/ActionButton/ActionShareButton/ActionShareButton';
import { CenteredContainer } from '../Shared/CenteredContainer/CenteredContainer';
import { BrokerAvatarCard } from './BrokerAvatarCard/BrokerAvatarCard';
import { getStyles } from './BrokerContact.styles';
import { BrokerContactForm } from './BrokerContactForm/BrokerContactForm';
import { BrokerContactWrapper } from './BrokerContactWrapper/BrokerContactWrapper';

interface Props {
  estate: EstateDetail;
  contactFormOpen: boolean;
  setContactFormOpen: (boolean) => void;
}

const t = getFixedT(null, 'core-immobilien');

export const BrokerContactDrawer: React.FunctionComponent<Props> = ({
  estate,
  contactFormOpen,
  setContactFormOpen,
}: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme).brokerContactDrawer;

  const { renderBookmarkButton } = useBookmarkContext();

  const [stickyVariantVisible, setStickyVariantVisible] = useState(false);
  const ref = useRef<HTMLDivElement>();

  const onScroll = useCallback(() => {
    if (ref.current) {
      const position = ref.current.getBoundingClientRect().top + ref.current.getBoundingClientRect().height;
      if (position < 0) {
        setStickyVariantVisible(true);
      } else {
        setStickyVariantVisible(false);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const showContactForm = useCallback(() => {
    setContactFormOpen(true);
  }, [setContactFormOpen]);

  const closeContactForm = useCallback(() => {
    setContactFormOpen(false);
  }, [setContactFormOpen]);

  const ContactTop = () => (
    <Box ref={ref}>
      <BrokerContactWrapper
        broker={estate.broker}
        providerCompany={estate.provider?.company}
        handleClick={showContactForm}
      />
    </Box>
  );

  const ContactBottomSticky = () => (
    <Box sx={{ ...styles.contactBottomOuterContainer, display: stickyVariantVisible ? 'inherit' : 'none' }}>
      <CenteredContainer>
        <Box sx={styles.contactBottomInnerContainer}>
          <Box sx={styles.avatarCardContainer}>
            <BrokerAvatarCard variant="compact" broker={estate.broker} providerCompany={estate.provider?.company} />
          </Box>
          <Box sx={styles.buttonContainer}>
            <Button sx={styles.button} onClick={showContactForm}>
              {t('detailPage.contractProvider')}
            </Button>
            <ActionShareButton shareTitle={`${estate.title}, ${estate.subtitle}`} />
            {renderBookmarkButton && renderBookmarkButton(estate.id)}
          </Box>
        </Box>
      </CenteredContainer>
    </Box>
  );

  return (
    <>
      <ContactTop />
      <ContactBottomSticky />

      <Drawer
        className="contact-form-container"
        anchor="right"
        open={contactFormOpen}
        PaperProps={{
          className: 'contact-form-container-paper',
          sx: styles.drawerPaper,
        }}
        onClose={closeContactForm}
      >
        <Box sx={styles.closeContainer}>
          <IconButton onClick={closeContactForm}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={styles.drawerContent}>
          <BrokerContactForm estate={estate} showHeadline={true} />
        </Box>
      </Drawer>
    </>
  );
};
