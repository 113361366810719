import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import { getFixedT } from 'i18next';
import { get } from 'lodash-es';
import React, { useCallback, useContext } from 'react';

import { ButtonGroupSelect } from '../../../../../components';
import { getAllMarketingTypes } from '../../../../../config';
import { CatalogsContext } from '../../../../../context';
import { getEstateTypeIdOptions } from '../../../../../utils';

interface Props {
  estateTypeIdFieldName: string;
  marketingTypeFieldName: string;
}
const t = getFixedT(null, 'core-immobilien');

export const MarketingTypeObjectTypeFilter: React.FunctionComponent<Props> = ({
  estateTypeIdFieldName,
  marketingTypeFieldName,
}: Props) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const estateTypeIdValue = get(values, estateTypeIdFieldName);
  const marketingValue = get(values, marketingTypeFieldName);

  const { estateSubTypes: catalogsEstateSubTypes } = useContext(CatalogsContext);

  const onButtonClick = useCallback(
    (value: MarketingType) => {
      setFieldTouched(marketingTypeFieldName, true);
      setFieldValue(marketingTypeFieldName, value, true);

      return true;
    },
    [marketingTypeFieldName, setFieldTouched, setFieldValue]
  );

  const onSelectClick = useCallback(
    (value: ObjectType) => {
      setFieldTouched(estateTypeIdFieldName, true);
      setFieldValue(estateTypeIdFieldName, value, true);

      return true;
    },
    [estateTypeIdFieldName, setFieldTouched, setFieldValue]
  );

  return (
    <Box sx={{ px: 6 }}>
      <Typography variant="h3">{t('objectType')}</Typography>
      <Grid container columnSpacing={5}>
        <Grid size={12}>
          <ButtonGroupSelect
            buttonOptions={getAllMarketingTypes(t)}
            selectOptions={getEstateTypeIdOptions(catalogsEstateSubTypes, marketingValue)}
            buttonValue={marketingValue}
            selectValue={estateTypeIdValue}
            highlightSelectedOption={true}
            onButtonClick={onButtonClick}
            onSelectClick={onSelectClick}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

MarketingTypeObjectTypeFilter.displayName = 'MarketingTypeObjectTypeFilter';
