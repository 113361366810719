import { Box, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Broker } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import React from 'react';

import { BrokerAvatarCard } from '../BrokerAvatarCard/BrokerAvatarCard';
import { getStyles } from '../BrokerContact.styles';

interface Props {
  broker: Broker;
  providerCompany: string;
  handleClick: () => void;
}

const t = getFixedT(null, 'core-immobilien');

export const BrokerContactWrapper: React.FunctionComponent<Props> = ({
  broker,
  providerCompany,
  handleClick,
}: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme).brokerContactWrapper;

  return (
    <Box sx={styles.outerContainer}>
      <Box sx={styles.avatarContainer}>
        <BrokerAvatarCard broker={broker} providerCompany={providerCompany} />
      </Box>
      <Box sx={styles.buttonContainer}>
        <Button fullWidth onClick={handleClick}>
          {t('detailPage.contractProvider')}
        </Button>
      </Box>
    </Box>
  );
};

BrokerContactWrapper.displayName = 'BrokerContactWrapper';
