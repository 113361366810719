import { Box, BoxProps } from '@mui/material';
import React from 'react';

type Props = BoxProps & {
  disableDefaultMarginBottom?: boolean;
};

const DEFAULT_MARGIN_BOTTOM = { xs: 8, lg: 12 };

export const SectionContainer = ({ disableDefaultMarginBottom = false, children, sx, ...rest }: Props): JSX.Element => (
  <Box
    sx={{
      marginBottom: disableDefaultMarginBottom ? 0 : DEFAULT_MARGIN_BOTTOM,
      '@media print': {
        display: 'block',
        pageBreakAfter: 'avoid',
        breakAfter: 'avoid',
        breakInside: 'avoid',
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
);

SectionContainer.displayName = 'SectionContainer';
