import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FinalContainer } from '@portals/core/src/components/FinalGrid/FinalGrid';
import React, { useCallback, useContext } from 'react';

import { CustomizeSearchButton, EstateResultSortOrder, SearchResultHeadline } from '../../../../components';
import { ResultListTypes } from '../../../../config';
import { CatalogsContext, ImmobilienApiSwitchContext } from '../../../../context';
import { EstateResultFilterView } from '../../../EstateResultFilterView/EstateResultFilterView';
import { EstateResultTypeToggle } from '../../../EstateResultFilterView/EstateResultTypeToggle/EstateResultTypeToggle';
import { EstateResultFilterView as EstateResultFilterViewImmobilienApi } from '../../../EstateResultFilterView/ImmobilienApi/EstateResultFilterView';
import { getStyles } from './FilterAndResultControlView.styles';
import { FilterAndResultControlViewProps } from './FilterAndResultControlView.types';
import { getNumberOfActiveFilters } from './FilterAndResultControlView.utils';
import {
  FilterAndResultControlViewSkeletonDesktop,
  FilterAndResultControlViewSkeletonMobile,
} from './FilterAndResultControlViewSkeleton';
import { StickyHeader } from './StickyHeader';

export const FilterAndResultControlView: React.FC<FilterAndResultControlViewProps> = ({
  searchParams,
  totalItems,
  estates,
  onClickSaveSearch,
  saveSearchButtonLabel,
  filterValues,
  showResultFilterDrawer,
  resultListType,
  onResultListToggleChange,
  onResultListSortChange,
  estateResultFilterViewInView,
  estateResultFilterViewRef,
  isLoading,
}: FilterAndResultControlViewProps) => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch and context
  const { useImmobilienApi } = useContext(ImmobilienApiSwitchContext);
  const { loading: catalogsLoading } = useContext(CatalogsContext);

  const showResultFilterDrawerCallback = useCallback(
    () => showResultFilterDrawer(true, null),
    [showResultFilterDrawer]
  );
  const isStickyHeader = estateResultFilterViewInView === false;
  const styles = getStyles();

  const buttonContent = (
    <CustomizeSearchButton
      numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
      onClick={showResultFilterDrawerCallback}
      sx={styles.customizeSearchButton}
    />
  );

  return (
    <>
      <FinalContainer sx={styles.finalContainer}>
        {useImmobilienApi && catalogsLoading ? (
          <FilterAndResultControlViewSkeletonDesktop />
        ) : (
          <>
            <Box sx={styles.headerContainer}>
              <SearchResultHeadline
                searchParams={searchParams}
                totalItems={totalItems}
                onClickSaveSearch={onClickSaveSearch}
                saveSearchButtonLabel={saveSearchButtonLabel}
                isSaveSearchButtonDisabled={false}
              />
            </Box>
            <Box sx={styles.estateResultFilterViewContainer}>
              {useImmobilienApi ? (
                <EstateResultFilterViewImmobilienApi
                  filterValues={filterValues}
                  numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
                  showResultFilterDrawer={showResultFilterDrawer}
                />
              ) : (
                <EstateResultFilterView
                  filterValues={filterValues}
                  numberOfActiveFilters={getNumberOfActiveFilters(filterValues)}
                  showResultFilterDrawer={showResultFilterDrawer}
                />
              )}
            </Box>
          </>
        )}
      </FinalContainer>
      <Box>
        <Box sx={styles.refBox} ref={estateResultFilterViewRef}></Box>
        <FinalContainer sx={styles.resultListControlsFinalContainer}>
          <Grid container sx={styles.resultListControlsCustomGridContainer}>
            {useImmobilienApi && catalogsLoading ? (
              <FilterAndResultControlViewSkeletonMobile />
            ) : (
              <>
                <Grid size={12} sx={styles.mobileSearchResultHeadline}>
                  <SearchResultHeadline
                    searchParams={searchParams}
                    totalItems={totalItems}
                    onClickSaveSearch={onClickSaveSearch}
                    saveSearchButtonLabel={saveSearchButtonLabel}
                    isSaveSearchButtonDisabled={false}
                    buttonContent={buttonContent}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 8, md: 7 }}>
                  {estates.length > 0 && (
                    <Box sx={styles.estateResultTypeToggle}>
                      <EstateResultTypeToggle
                        value={resultListType}
                        onChange={onResultListToggleChange}
                        isStickyHeader={isStickyHeader}
                        isLoading={isLoading}
                      />
                      {resultListType === ResultListTypes.LIST && (
                        <Box sx={styles.estateResultSortOrder}>
                          <EstateResultSortOrder
                            value={filterValues.sortBy}
                            onChange={onResultListSortChange}
                            renderValueWithLabel
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </FinalContainer>
      </Box>
      {isStickyHeader && (
        <StickyHeader
          searchParams={searchParams}
          totalItems={totalItems}
          estates={estates}
          onClickSaveSearch={onClickSaveSearch}
          saveSearchButtonLabel={saveSearchButtonLabel}
          filterValues={filterValues}
          showResultFilterDrawer={showResultFilterDrawer}
          estateResultFilterViewInView={estateResultFilterViewInView}
          resultListType={resultListType}
          onResultListToggleChange={onResultListToggleChange}
          onResultListSortChange={onResultListSortChange}
          estateResultFilterViewRef={estateResultFilterViewRef}
        />
      )}
    </>
  );
};

FilterAndResultControlView.displayName = 'FilterAndResultControlView';
