import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, SxProps<Theme>> => ({
  inputContainer: {
    mb: { xs: 4, lg: 5 },
  },
  link: { underline: 'none', color: theme.palette.primary.main },
  infoContainer: { whiteSpace: 'normal' },
  legalCheckContainer: { pb: { xs: 4, lg: 8 } },
  contractProviderTypography: {
    mb: 7,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    mb: { xs: 6, lg: 8 },
  },
  avatar: {
    width: 90,
    height: 90,
    mr: 5,
  },
  brokerTypography: {
    mb: 3,
  },
  instituteTypography: {
    mb: 0,
    color: { xs: theme.palette.secondary.dark, lg: theme.palette.text.secondary },
  },
  successContainer: {
    fontSize: '36px',
    lineHeight: '36px',
    mb: 6,
    textAlign: 'center',
  },
  messageTypography: { textAlign: 'center', px: 5 },
  dividerContainer: { py: 6 },
  financeCertificateDescriptionTypography: {
    pt: 6,
  },
  financeButtonLabelContainer: {
    py: 6,
  },
});
