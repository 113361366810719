import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { ImprintData } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { forEach, get, has, isEmpty, omit, omitBy } from 'lodash-es';
import React from 'react';

interface ImprintProps {
  data: ImprintData;
}

const mapI18nKeys = {
  vertretungsberechtigter: 'imprint.authorizedRepresentative',
  berufsaufsichtsbehoerde: 'imprint.professionaRegulator',
  handelsregister: 'imprint.commercialRegister',
  handelsregister_nr: 'imprint.companRegistrationNumber',
  ['umsst-id']: 'imprint.taxId',
  steuernummer: 'imprint.taxNumber',
  weiteres: 'imprint.additional',
  info: 'imprint.info',
};

const t = getFixedT(null, 'core-immobilien');

export default function Imprint({ data }: ImprintProps): React.ReactElement {
  const plainImprintText = get(data, 'plain', '');
  const infoText = get(data, 'info', '');
  const odrText = get(data, 'odr', '').replace(/\\"/g, '"');

  const renderImprint = () => {
    if (has(data, 'structured')) {
      const companyData = [];
      const finalImprintData = [];
      const structured = get(data, 'structured', '');

      companyData.push(get(structured, 'firmenname', ''));
      companyData.push(get(structured, 'firmenanschrift', ''));
      companyData.push(`${t('imprint.phone')}: ${get(structured, 'telefon', '')}`);

      const structuredWithoutCompanyData = omit(structured, ['firmenname', 'firmenanschrift', 'telefon']);
      const structuredWithoutCompanyDataAndEmptyEntries = omitBy(structuredWithoutCompanyData, (val) => isEmpty(val));

      forEach(structuredWithoutCompanyDataAndEmptyEntries, function (value, key) {
        const i18nKey = get(mapI18nKeys, key, null);
        const label = i18nKey ? t(i18nKey) : key;
        finalImprintData.push(`${label}: ${value}`);
      });

      return (
        <>
          {companyData.map((detail) => (
            <>
              {detail}
              <br />
            </>
          ))}

          <Box sx={{ mt: 6 }}>
            {finalImprintData.map((detail) => (
              <>
                {detail}
                <br />
              </>
            ))}
          </Box>
        </>
      );
    }
    return plainImprintText;
  };

  return (
    <Box>
      <Typography variant="body2" component="p">
        {renderImprint()}
      </Typography>
      <Typography variant="body2" component="p" sx={{ mt: 6 }}>
        {infoText}
      </Typography>
      <Typography variant="body2" component="p" sx={{ mt: 6 }} dangerouslySetInnerHTML={{ __html: odrText || null }} />
    </Box>
  );
}

Imprint.displayName = 'Imprint';
