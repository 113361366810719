import { Box, SxProps } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { EstateMainFact, MainfactCategory, MainFactProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import React from 'react';

import { getMainfactCategoryIcon } from '../../config';

const t = getFixedT(null, 'core-immobilien');

const getLabel = (mainfact: EstateMainFact) => {
  if (mainfact.category === MainfactCategory.ROOMS) {
    return t('estateCard.roomCount');
  }
  return mainfact.label || '';
};

const getIcon = (category: MainfactCategory) => {
  const Icon = getMainfactCategoryIcon(category);

  if (!Icon) {
    return null;
  }

  return (
    <Icon
      sx={{
        marginRight: { xs: theme.spacing(2), lg: theme.spacing(4) },
        width: { xs: theme.spacing(5), lg: theme.spacing(6) },
        height: { xs: theme.spacing(5), lg: theme.spacing(6) },
      }}
    />
  );
};
export interface MainFactComponentProps extends MainFactProps {
  list: Array<EstateMainFact>;
  outerBoxSx?: SxProps;
}

export const MainFacts: React.FC<MainFactComponentProps> = ({ list, outerBoxSx = {} }: MainFactComponentProps) => {
  if (!list?.length) {
    return null;
  }

  const renderMainFact = (mainfact: EstateMainFact, key) => (
    <Typography
      key={key}
      className="estate-mainfact"
      variant="h6"
      component="div"
      sx={{ display: 'flex', alignItems: 'center', lineHeight: 0, color: theme.palette.secondary.darker }}
    >
      {getIcon(mainfact.category)}
      <span>{`${mainfact.value} ${getLabel(mainfact)}`}</span>
    </Typography>
  );

  if (Object.keys(outerBoxSx).length > 0) {
    return <Box sx={outerBoxSx}>{list.map(renderMainFact)}</Box>;
  }

  return <>{list.map(renderMainFact)}</>;
};

MainFacts.displayName = 'MainFacts';
