import { getFixedT } from 'i18next';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';

import { UserContext } from '../../components';
import { loginConfig } from '../../context';
import { useIsBookmarked, useSearchIsSaved } from '../../hooks';
import { activateAppToken, performAfterRegistrationAction } from '../../utils';

export const QUERY_ADD_BOOKMARK_ID = 'addBookmark';
export const QUERY_AFTER_REGISTRATION_HASH = 'afterRegistrationHash';
export const QUERY_ACTIVATION_TOKEN = 'activationToken';

const t = getFixedT(null, 'core-immobilien');

export function useInitialUserActionRunner(): () => Promise<void> {
  const { bookmarks } = useContext(UserContext);
  const { toggleBookmark } = useIsBookmarked();
  const { loadSavedSearches } = useSearchIsSaved();
  const { enqueueSnackbar } = useSnackbar();

  const handleBookmarkAction = async (estateId?: string) => {
    if (!estateId) {
      return;
    }

    if (bookmarks.includes(estateId)) {
      return;
    }

    await toggleBookmark(estateId);
  };

  const handleAfterRegistrationAction = async (hash?: string) => {
    if (!hash) {
      return;
    }
    await performAfterRegistrationAction(loginConfig.userApiUrl, hash);
    await loadSavedSearches();
  };

  const handleAppTokenActivation = async (activationToken?: string) => {
    if (!activationToken) {
      return;
    }

    const activationSuccessful = await activateAppToken(loginConfig.userApiUrl, activationToken);

    if (activationSuccessful) {
      enqueueSnackbar(t('userDashboard.appTokenActivation.success'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('userDashboard.appTokenActivation.error'), { variant: 'error' });
    }
  };

  return async () => {
    const queryParams = new URLSearchParams(window.location.search);

    await handleBookmarkAction(queryParams.get(QUERY_ADD_BOOKMARK_ID));
    await handleAfterRegistrationAction(queryParams.get(QUERY_AFTER_REGISTRATION_HASH));
    await handleAppTokenActivation(queryParams.get(QUERY_ACTIVATION_TOKEN));
  };
}
