import { Box, FormHelperText, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { MarketingType, ObjectType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import { getFixedT } from 'i18next';
import { find } from 'lodash-es';
import React, { useEffect } from 'react';
import { number } from 'yup';

import { usePriceFormatter, useRoomFormatter, useSquareMetersFormatter } from '../../../hooks';
import { CustomSelect } from '../EstateSelect/EstateSelect';

export enum EstateFilterTypes {
  MAX_PRICE = 'maxPrice',
  MIN_LIVING_SPACE = 'minLivingSpace',
  MIN_NUMBER_ROOMS = 'minNumberRooms',
  MIN_PROPERTY_SIZE = 'minPropertySize',
  MIN_TOTAL_SPACE = 'minTotalSpace',
  MAX_MARKET_VALUE = 'maxMarketValue',
  MAX_RENT = 'maxRent',
  MAX_LEASE = 'maxLease',
}

interface Props {
  marketingType: MarketingType;
  objectType: ObjectType;
  setValidationSchema: (validationSchema: any) => void;
}

const t = getFixedT(null, 'core-immobilien');

export const EstateSearchFilterView = ({ marketingType, objectType, setValidationSchema }: Props) => {
  const priceFormatter = usePriceFormatter();
  const roomFormatter = useRoomFormatter();
  const squareMetersFormatter = useSquareMetersFormatter();
  const theme = useTheme();

  const selectStyles = {
    ...theme.typography.button,
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(3),
  } as const;

  const defaultLivingSpaceValuesFlat = [
    {
      value: Number.MAX_VALUE,
      label: t('estateSearch.any'),
    },
    {
      value: 40,
      label: squareMetersFormatter(40, null),
    },
    {
      value: 50,
      label: squareMetersFormatter(50, null),
    },
    {
      value: 60,
      label: squareMetersFormatter(60, null),
    },
    {
      value: 80,
      label: squareMetersFormatter(80, null),
    },
    {
      value: 120,
      label: squareMetersFormatter(120, null),
    },
  ];

  const defaultLivingSpaceValuesHouse = [
    {
      value: Number.MAX_VALUE,
      label: t('estateSearch.any'),
    },
    {
      value: 80,
      label: squareMetersFormatter(80, null),
    },
    {
      value: 100,
      label: squareMetersFormatter(100, null),
    },
    {
      value: 120,
      label: squareMetersFormatter(120, null),
    },
    {
      value: 140,
      label: squareMetersFormatter(140, null),
    },
    {
      value: 160,
      label: squareMetersFormatter(160, null),
    },
    {
      value: 200,
      label: squareMetersFormatter(200, null),
    },
  ];

  const defaultRoomValuesHouse = [
    {
      value: Number.MAX_VALUE,
      label: t('estateSearch.any'),
    },
    {
      value: 2,
      label: roomFormatter(2, null),
    },
    {
      value: 2.5,
      label: roomFormatter(2.5, null),
    },
    {
      value: 3,
      label: roomFormatter(3, null),
    },
    {
      value: 4,
      label: roomFormatter(4, null),
    },
    {
      value: 5,
      label: roomFormatter(5, null),
    },
    {
      value: 6,
      label: roomFormatter(6, null),
    },
  ];

  const defaultRoomValuesFlat = [
    {
      value: Number.MAX_VALUE,
      label: t('estateSearch.any'),
    },
    {
      value: 1,
      label: roomFormatter(1, null),
    },
    {
      value: 1.5,
      label: roomFormatter(1.5, null),
    },
    {
      value: 2,
      label: roomFormatter(2, null),
    },
    {
      value: 2.5,
      label: roomFormatter(2.5, null),
    },
    {
      value: 3,
      label: roomFormatter(3, null),
    },
    {
      value: 4,
      label: roomFormatter(4, null),
    },
    {
      value: 5,
      label: roomFormatter(5, null),
    },
  ];

  const defaultPropertySizeValues = [
    {
      value: Number.MAX_VALUE,
      label: t('estateSearch.any'),
    },
    {
      value: 300,
      label: squareMetersFormatter(300, null),
    },
    {
      value: 400,
      label: squareMetersFormatter(400, null),
    },
    {
      value: 500,
      label: squareMetersFormatter(500, null),
    },
    {
      value: 600,
      label: squareMetersFormatter(600, null),
    },
    {
      value: 800,
      label: squareMetersFormatter(800, null),
    },
    {
      value: 1000,
      label: squareMetersFormatter(1000, null),
    },
  ];

  const defaultTotalSpaceValues = [
    {
      value: Number.MAX_VALUE,
      label: t('estateSearch.any'),
    },
    {
      value: 50,
      label: squareMetersFormatter(50, null),
    },
    {
      value: 80,
      label: squareMetersFormatter(80, null),
    },
    {
      value: 100,
      label: squareMetersFormatter(100, null),
    },
    {
      value: 200,
      label: squareMetersFormatter(200, null),
    },
    {
      value: 500,
      label: squareMetersFormatter(500, null),
    },
    {
      value: 1000,
      label: squareMetersFormatter(1000, null),
    },
  ];

  const priceValidationSchema = () => number().integer().min(0).max(999999999).nullable();
  const livingSpaceValidationSchema = () => number().integer().min(0).max(10000).nullable();
  const numberRoomsValidationSchema = () => number().min(0).max(10000).nullable();
  const propertySizeValidationSchema = () => number().integer().min(0).max(10000).nullable();
  const totalSpaceValidationSchema = () => number().integer().min(0).max(10000).nullable();

  const filterConfig = [
    {
      marketingType: MarketingType.BUY,
      objectType: ObjectType.FLAT,
      fields: [
        {
          type: EstateFilterTypes.MAX_PRICE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 100000,
              label: priceFormatter(null, 100000),
            },
            {
              value: 150000,
              label: priceFormatter(null, 150000),
            },
            {
              value: 200000,
              label: priceFormatter(null, 200000),
            },
            {
              value: 300000,
              label: priceFormatter(null, 300000),
            },
            {
              value: 400000,
              label: priceFormatter(null, 400000),
            },
            {
              value: 500000,
              label: priceFormatter(null, 500000),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_LIVING_SPACE, defaultValues: defaultLivingSpaceValuesFlat },
        { type: EstateFilterTypes.MIN_NUMBER_ROOMS, defaultValues: defaultRoomValuesFlat },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minLivingSpace: livingSpaceValidationSchema(),
        minNumberRooms: numberRoomsValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.BUY,
      objectType: ObjectType.HOUSE,
      fields: [
        {
          type: EstateFilterTypes.MAX_PRICE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 150000,
              label: priceFormatter(null, 150000),
            },
            {
              value: 200000,
              label: priceFormatter(null, 200000),
            },
            {
              value: 300000,
              label: priceFormatter(null, 300000),
            },
            {
              value: 400000,
              label: priceFormatter(null, 400000),
            },
            {
              value: 500000,
              label: priceFormatter(null, 500000),
            },
            {
              value: 750000,
              label: priceFormatter(null, 750000),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_LIVING_SPACE, defaultValues: defaultLivingSpaceValuesHouse },
        { type: EstateFilterTypes.MIN_NUMBER_ROOMS, defaultValues: defaultRoomValuesHouse },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minLivingSpace: livingSpaceValidationSchema(),
        minNumberRooms: numberRoomsValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.BUY,
      objectType: ObjectType.PROPERTY,
      fields: [
        {
          type: EstateFilterTypes.MAX_PRICE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 50000,
              label: priceFormatter(null, 50000),
            },
            {
              value: 75000,
              label: priceFormatter(null, 75000),
            },
            {
              value: 100000,
              label: priceFormatter(null, 100000),
            },
            {
              value: 150000,
              label: priceFormatter(null, 150000),
            },
            {
              value: 200000,
              label: priceFormatter(null, 200000),
            },
            {
              value: 300000,
              label: priceFormatter(null, 300000),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_PROPERTY_SIZE, defaultValues: defaultPropertySizeValues },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minPropertySize: propertySizeValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.BUY,
      objectType: ObjectType.FORECLOSURE,
      fields: [
        {
          type: EstateFilterTypes.MAX_MARKET_VALUE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 100000,
              label: priceFormatter(null, 100000),
            },
            {
              value: 150000,
              label: priceFormatter(null, 150000),
            },
            {
              value: 200000,
              label: priceFormatter(null, 200000),
            },
            {
              value: 300000,
              label: priceFormatter(null, 300000),
            },
            {
              value: 500000,
              label: priceFormatter(null, 500000),
            },
            {
              value: 750000,
              label: priceFormatter(null, 750000),
            },
          ],
        },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.BUY,
      objectType: ObjectType.INTEREST,
      fields: [
        {
          type: EstateFilterTypes.MAX_PRICE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 200000,
              label: priceFormatter(null, 200000),
            },
            {
              value: 300000,
              label: priceFormatter(null, 300000),
            },
            {
              value: 500000,
              label: priceFormatter(null, 500000),
            },
            {
              value: 750000,
              label: priceFormatter(null, 750000),
            },
            {
              value: 1000000,
              label: priceFormatter(null, 1000000),
            },
            {
              value: 1500000,
              label: priceFormatter(null, 1500000),
            },
          ],
        },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.BUY,
      objectType: ObjectType.BUSINESS,
      fields: [
        {
          type: EstateFilterTypes.MAX_PRICE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 200000,
              label: priceFormatter(null, 200000),
            },
            {
              value: 500000,
              label: priceFormatter(null, 500000),
            },
            {
              value: 1000000,
              label: priceFormatter(null, 1000000),
            },
            {
              value: 1500000,
              label: priceFormatter(null, 1500000),
            },
            {
              value: 3000000,
              label: priceFormatter(null, 3000000),
            },
            {
              value: 5000000,
              label: priceFormatter(null, 5000000),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_TOTAL_SPACE, defaultValues: defaultTotalSpaceValues },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minTotalSpace: totalSpaceValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.RENT,
      objectType: ObjectType.FLAT,
      fields: [
        {
          type: EstateFilterTypes.MAX_RENT,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 500,
              label: priceFormatter(null, 500),
            },
            {
              value: 750,
              label: priceFormatter(null, 750),
            },
            {
              value: 1000,
              label: priceFormatter(null, 1000),
            },
            {
              value: 1250,
              label: priceFormatter(null, 1250),
            },
            {
              value: 1500,
              label: priceFormatter(null, 1500),
            },
            {
              value: 2000,
              label: priceFormatter(null, 2000),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_LIVING_SPACE, defaultValues: defaultLivingSpaceValuesFlat },
        { type: EstateFilterTypes.MIN_NUMBER_ROOMS, defaultValues: defaultRoomValuesFlat },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minLivingSpace: livingSpaceValidationSchema(),
        minNumberRooms: numberRoomsValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.RENT,
      objectType: ObjectType.HOUSE,
      fields: [
        {
          type: EstateFilterTypes.MAX_RENT,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 750,
              label: priceFormatter(null, 750),
            },
            {
              value: 1000,
              label: priceFormatter(null, 1000),
            },
            {
              value: 1250,
              label: priceFormatter(null, 1250),
            },
            {
              value: 1500,
              label: priceFormatter(null, 1500),
            },
            {
              value: 2000,
              label: priceFormatter(null, 2000),
            },
            {
              value: 2500,
              label: priceFormatter(null, 2500),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_LIVING_SPACE, defaultValues: defaultLivingSpaceValuesHouse },
        { type: EstateFilterTypes.MIN_NUMBER_ROOMS, defaultValues: defaultRoomValuesHouse },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minLivingSpace: livingSpaceValidationSchema(),
        minNumberRooms: numberRoomsValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.RENT,
      objectType: ObjectType.PROPERTY,
      fields: [
        {
          type: EstateFilterTypes.MAX_LEASE,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 300,
              label: priceFormatter(null, 300),
            },
            {
              value: 500,
              label: priceFormatter(null, 500),
            },
            {
              value: 750,
              label: priceFormatter(null, 750),
            },
            {
              value: 1000,
              label: priceFormatter(null, 1000),
            },
            {
              value: 1250,
              label: priceFormatter(null, 1250),
            },
            {
              value: 1500,
              label: priceFormatter(null, 1500),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_PROPERTY_SIZE, defaultValues: defaultPropertySizeValues },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minPropertySize: propertySizeValidationSchema(),
      },
    },
    {
      marketingType: MarketingType.RENT,
      objectType: ObjectType.BUSINESS,
      fields: [
        {
          type: EstateFilterTypes.MAX_RENT,
          defaultValues: [
            {
              value: Number.MAX_VALUE,
              label: t('estateSearch.any'),
            },
            {
              value: 500,
              label: priceFormatter(null, 500),
            },
            {
              value: 1000,
              label: priceFormatter(null, 1000),
            },
            {
              value: 1500,
              label: priceFormatter(null, 1500),
            },
            {
              value: 2000,
              label: priceFormatter(null, 2000),
            },
            {
              value: 3000,
              label: priceFormatter(null, 3000),
            },
            {
              value: 5000,
              label: priceFormatter(null, 5000),
            },
          ],
        },
        { type: EstateFilterTypes.MIN_TOTAL_SPACE, defaultValues: defaultTotalSpaceValues },
      ],
      validationSchema: {
        maxPrice: priceValidationSchema(),
        minTotalSpace: totalSpaceValidationSchema(),
      },
    },
  ];

  const singleFilterConfig = find(filterConfig, { marketingType, objectType });

  useEffect(() => {
    setValidationSchema(singleFilterConfig.validationSchema);
  }, []);

  const { values, errors, setFieldTouched, setFieldValue } = useFormikContext();

  const checkIfTypeIsOther = (value) => {
    return value === Number.MAX_VALUE ? null : value;
  };

  const setFormikValue = (type, value) => {
    setFieldTouched(type, true);
    setFieldValue(type, checkIfTypeIsOther(value), true);
  };

  const getFieldErrorMessage = (message) => (
    <Box sx={{ pl: theme.spacing(4) }}>
      <FormHelperText error={true}>{message}</FormHelperText>
    </Box>
  );

  return singleFilterConfig.fields.map((field) => {
    const initialValue = values[field.type];
    switch (field.type) {
      case EstateFilterTypes.MAX_PRICE:
      case EstateFilterTypes.MAX_RENT:
      case EstateFilterTypes.MAX_MARKET_VALUE:
      case EstateFilterTypes.MAX_LEASE:
        return (
          <Grid size="grow" key={field.type}>
            <CustomSelect
              customInput
              customInputPlaceholder={t('priceSearchPlaceholder')}
              label={t('price')}
              items={field.defaultValues}
              /* eslint-disable-next-line react/jsx-no-bind */
              onChange={(value) => {
                setFormikValue(field.type, value);
              }}
              /* eslint-disable-next-line react/jsx-no-bind */
              formatValue={(value) => priceFormatter(null, value)}
              selectProps={{ error: Boolean(errors[field.type]) }}
              selectStyles={selectStyles}
              initialValue={initialValue}
            />
            {getFieldErrorMessage(errors[field.type])}
          </Grid>
        );
      case EstateFilterTypes.MIN_LIVING_SPACE:
        return (
          <Grid size="grow" key={field.type}>
            <CustomSelect
              customInput
              customInputPlaceholder={t('livingSpaceSearchPlaceholder')}
              label={t('livingSpace')}
              items={field.defaultValues}
              /* eslint-disable-next-line react/jsx-no-bind */
              onChange={(value) => {
                setFormikValue(field.type, value);
              }}
              /* eslint-disable-next-line react/jsx-no-bind */
              formatValue={(value) => squareMetersFormatter(value, null)}
              selectProps={{ error: Boolean(errors[field.type]) }}
              selectStyles={selectStyles}
              initialValue={initialValue}
            />
            {getFieldErrorMessage(errors[field.type])}
          </Grid>
        );
      case EstateFilterTypes.MIN_NUMBER_ROOMS:
        return (
          <Grid size="grow" key={field.type}>
            <CustomSelect
              customInput
              customInputPlaceholder={t('roomSearchPlaceholder')}
              label={t('room')}
              items={field.defaultValues}
              /* eslint-disable-next-line react/jsx-no-bind */
              onChange={(value) => {
                setFormikValue(field.type, value);
              }}
              /* eslint-disable-next-line react/jsx-no-bind */
              formatValue={(value) => roomFormatter(value, null)}
              selectProps={{ error: Boolean(errors[field.type]) }}
              selectStyles={selectStyles}
              initialValue={initialValue}
            />
            {getFieldErrorMessage(errors[field.type])}
          </Grid>
        );
      case EstateFilterTypes.MIN_PROPERTY_SIZE:
        return (
          <Grid size="grow" key={field.type}>
            <CustomSelect
              customInput
              customInputPlaceholder={t('propertySizeSearchPlaceholder')}
              label={t('propertySize')}
              items={field.defaultValues}
              /* eslint-disable-next-line react/jsx-no-bind */
              onChange={(value) => {
                setFormikValue(field.type, value);
              }}
              /* eslint-disable-next-line react/jsx-no-bind */
              formatValue={(value) => squareMetersFormatter(value, null)}
              selectProps={{ error: Boolean(errors[field.type]) }}
              selectStyles={selectStyles}
              initialValue={initialValue}
            />
            {getFieldErrorMessage(errors[field.type])}
          </Grid>
        );
      case EstateFilterTypes.MIN_TOTAL_SPACE:
        return (
          <Grid size="grow" key={field.type}>
            <CustomSelect
              customInput
              customInputPlaceholder={t('totalSpaceSearchPlaceholder')}
              label={t('totalSpace')}
              items={field.defaultValues}
              /* eslint-disable-next-line react/jsx-no-bind */
              onChange={(value) => {
                setFormikValue(field.type, value);
              }}
              /* eslint-disable-next-line react/jsx-no-bind */
              formatValue={(value) => squareMetersFormatter(value, null)}
              selectProps={{ error: Boolean(errors[field.type]) }}
              selectStyles={selectStyles}
              initialValue={initialValue}
            />
            {getFieldErrorMessage(errors[field.type])}
          </Grid>
        );
      default:
        return null;
    }
  });
};

EstateSearchFilterView.displayName = 'EstateSearchFilterView';
