import { getFixedT } from 'i18next';
import { useCallback } from 'react';

export type formatterFunction = (min: number | null, max: number | null, defaultLabel?: string) => string;

const t = getFixedT(null, 'core-immobilien');

export function useRoomFormatter(): formatterFunction {
  return useCallback((min, max, defaultLabel) => {
    if (min === null && max === null) {
      return defaultLabel ? defaultLabel : '';
    }

    if (min !== null && max !== null) {
      return t('minMaxRooms', { min, max });
    }

    if (min) {
      return t('minRooms', { min });
    }

    if (max) {
      return t('maxRooms', { max });
    }

    return defaultLabel ? defaultLabel : '';
  }, []);
}
