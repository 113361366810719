import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { RadioGroup } from '@portals/core/src/components/RadioGroup/RadioGroup';
import { theme } from '@portals/core/src/themes/sde/main';
import React, { useCallback } from 'react';

interface SelectOption {
  value: number | string;
  label: string;
}

interface Props {
  headline: string;
  selectedOptions: SelectOption[];
  setSelectedOptions: (selectedOptions: SelectOption[]) => void;
  options: SelectOption[];
  multiple?: boolean;
}

export const EstateFeatureBox = ({
  selectedOptions,
  setSelectedOptions,
  headline,
  options,
  multiple,
}: Props): React.ReactElement => {
  const handleUpdateList = (option, checked) => {
    if (checked) {
      return [...selectedOptions, option];
    }
    return selectedOptions.filter((selectedOption) => selectedOption.value !== option.value);
  };

  const handleChange = (option: SelectOption, checked: boolean) => {
    setSelectedOptions(handleUpdateList(option, checked));
  };

  const handleChecked = (option: SelectOption): boolean => {
    return selectedOptions.some((selectedOption) => selectedOption.value === option.value);
  };

  const renderCheckbox = (option) => {
    return (
      <Checkbox
        disableRipple
        checked={handleChecked(option)}
        /* eslint-disable-next-line react/jsx-no-bind */
        onChange={(event) => handleChange(option, event.target.checked)}
      />
    );
  };

  const onChange = useCallback(
    (e, value) => {
      setSelectedOptions(options.filter((selectedOption) => String(selectedOption.value) === value));
    },
    [options, setSelectedOptions]
  );

  const renderSelect = () => {
    if (multiple) {
      return (
        <FormGroup sx={{ width: '100%' }}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                py: 4,
                my: 0,
                mr: { xs: '-17px', md: '-15px', lg: '-9px' },
              }}
              labelPlacement="start"
              label={
                <Typography color="rgb(68, 68, 68)" variant="button">
                  {option.label}
                </Typography>
              }
              control={renderCheckbox(option)}
            />
          ))}
        </FormGroup>
      );
    }

    return (
      <RadioGroup sx={{ width: '100%' }} value={String(selectedOptions[0]?.value)} name={headline} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              py: 4,
              my: 0,
              mr: { xs: '-17px', md: '-15px', lg: '-9px' },
            }}
            labelPlacement="start"
            key={option.value}
            value={option.value}
            label={
              <Typography color="rgb(68, 68, 68)" variant="button">
                {option.label}
              </Typography>
            }
            control={
              <Radio
                sx={{
                  '& svg': {
                    width: '20px',
                    height: '20px',
                  },
                }}
              />
            }
          />
        ))}
      </RadioGroup>
    );
  };

  const renderListItem = () => {
    return <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>{renderSelect()}</Box>;
  };

  return (
    <Box sx={{ p: '20px' }}>
      <FormControl fullWidth>
        <FormLabel sx={{ width: '100%' }} component="legend">
          <Typography variant="h4" sx={{ color: theme.palette.common.black }}>
            {headline}
          </Typography>
        </FormLabel>
        {renderListItem()}
      </FormControl>
    </Box>
  );
};

EstateFeatureBox.displayName = 'EstateFeatureBox';
