import { Box, Typography } from '@mui/material';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import { theme } from '@portals/core/src/themes/sde/main';
import { FitToBoundsHandler } from '@portals/maps/src/components/FitToBoundsHandler/FitToBoundsHandler';
import { MapContainer } from '@portals/maps/src/containers/MapContainer/MapContainer';
import { MarkerClusterGroup } from '@portals/maps/src/containers/MarkerClusterGroup/MarkerClusterGroup';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { LatLngExpression } from 'leaflet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MapEstateDialog } from '../MapEstateDialog/MapEstateDialog';
import { MarkerGlobalStyles, ZoomControlGlobalStyles } from './EstateResultMapView.styles';
import { EstateMarkerComponent } from './Marker/EstateMarkerComponent';

interface Props {
  estates?: Array<EstateItem>;
}

const getCoordinatesFromEstates = (estates) => {
  if (!estates || estates.length === 0) {
    return [];
  }
  return estates.slice(0, 20).map((item) => {
    const { lat, lng } = item;
    return {
      lat,
      lng,
    };
  });
};

export const EstateResultMapView: React.FunctionComponent<Props> = ({ estates }: Props) => {
  const [filteredClusterEstates, setFilteredClusterEstates] = useState([]);
  const [mapIsPositioned, setMapIsPositioned] = useState(false);
  const TILE_URL = ConfigProvider.getConfig().get('YELLOWMAP_TILE_URL');

  useEffect(() => {
    hideEstateDetails();
  }, [estates]);

  const coordinates = useMemo<LatLngExpression[]>(() => {
    if (estates.length > 0) {
      setMapIsPositioned(true);
    }
    return getCoordinatesFromEstates(estates);
  }, [estates]);

  const center = coordinates.length > 0 ? coordinates[0] : undefined;

  const findEstatesByIds = (estateIds: Array<string>) => {
    return estates.filter((item) => {
      return estateIds.indexOf(item.id) !== -1;
    });
  };
  const onClickEstateMarker = useCallback(
    (estateMarker, estateID = '') => {
      setFilteredClusterEstates(findEstatesByIds([estateID]));
    },
    [estates]
  );

  const hideEstateDetails = useCallback(() => {
    setFilteredClusterEstates([]);
  }, []);

  const showFilteredClusterEstates = useCallback(
    (e) => {
      const parentChildClusterLength = e.propagatedFrom.__parent._childClusters.length;
      const hasNoChildClusters = e.propagatedFrom._childClusters.length === 0;
      if (parentChildClusterLength === 1 && hasNoChildClusters) {
        const estateIds = e.propagatedFrom.getAllChildMarkers().map((estate) => estate.options.options.estateId);
        const idEstates = findEstatesByIds(estateIds);
        setFilteredClusterEstates(idEstates);
      }
    },
    [estates]
  );

  const onClickMarkerClusterGroup = useCallback(
    (e) => {
      showFilteredClusterEstates(e);
    },
    [showFilteredClusterEstates]
  );

  if (estates.length === 0) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" sx={{ pb: 8 }}>
          Immobilien werden geladen!
          <br />
          <LoadingDots />
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ position: 'relative', height: 1, overflow: 'hidden' }}>
      <Box sx={{ position: 'absolute', left: 0, top: 0, height: 1, width: 1 }}>
        <MapContainer
          className="estate-maps"
          containerProps={{
            width: '100%',
            height: '100%',
          }}
          style={{ width: '100%', height: '100%' }}
          center={center}
          zoom={12}
          minZoom={7}
          maxZoom={18}
          url={TILE_URL}
          zoomControl={true}
          scrollWheelZoom={false}
        >
          {!mapIsPositioned && <FitToBoundsHandler coordinates={coordinates} padding={12} />}
          {MarkerGlobalStyles(theme)}
          {ZoomControlGlobalStyles(theme)}
          <MarkerClusterGroup spiderfyOnMaxZoom={false} showCoverageOnHover={false} onClick={onClickMarkerClusterGroup}>
            {estates.map((estate) => {
              return (
                <EstateMarkerComponent key={estate.id} estateItem={estate} onClickEstateMarker={onClickEstateMarker} />
              );
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </Box>
      <MapEstateDialog onClose={hideEstateDetails} estates={filteredClusterEstates} />
    </Box>
  );
};

EstateResultMapView.displayName = 'EstateResultMapView';
