import { Box, Drawer, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Close } from '@portals/icons';
import { getFixedT } from 'i18next';
import { assign } from 'lodash-es';
import React, { useCallback } from 'react';

import { EstateResultSortOrder } from '../../../components';
import { Criteria } from '../../../config';
import type { FilterValuesProps } from '../../../types';
import { AllFilters } from './AllFilters/AllFilters';
import { Buttons } from './Buttons/Buttons';
import { FeatureFilter } from './FeatureFilter/FeatureFilter';
import { FromToFilter } from './FromToFilter/FromToFilter';
import { MarketingTypeObjectTypeFilter } from './MarketingTypeObjectTypeFilter/MarketingTypeObjectTypeFilter';
import { PerimeterFilter } from './PerimeterFilter/PerimeterFilter';
import { ZipCityEstateIdFilter } from './ZipCityEstateIdFilter/ZipCityEstateIdFilter';

interface AllFilterProps {
  visible: boolean;
  filterValues: FilterValuesProps;
  criteria: Criteria | null;
  showAllFilter: boolean;
  changeFilterValues: (changedFilterValues: FilterValuesProps) => void;
  changeCriteria: (criteria: Criteria) => void;
  close: () => void;
  resetFilters: () => void;
}

const t = getFixedT(null, 'core-immobilien');

export const FilterDrawer: React.FC<AllFilterProps> = ({
  filterValues,
  visible,
  criteria,
  showAllFilter,
  changeFilterValues,
  changeCriteria,
  close,
  resetFilters,
}: AllFilterProps) => {
  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      resetFilters();

      if (showAllFilter && criteria !== null) {
        changeCriteria(null);
        return;
      }

      close();
    },
    [changeCriteria, close, criteria, resetFilters, showAllFilter]
  );

  const handleResultListSortChange = useCallback(
    (value) => {
      const changedFilterValues = assign({}, filterValues, { sortBy: value });
      changeFilterValues(changedFilterValues);
    },
    [changeFilterValues, filterValues]
  );

  const renderSort = () => {
    if (showAllFilter && criteria === null) {
      return (
        <Box sx={{ px: 6, pb: 6, display: { xs: 'inherit', md: 'inherit', lg: 'none' } }}>
          <Typography variant="h3">{t('sortBy')}</Typography>
          <Grid container columnSpacing={5}>
            <Grid size={12}>
              <EstateResultSortOrder
                value={filterValues.sortBy}
                onChange={handleResultListSortChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      );
    }

    return null;
  };

  const renderFilter = () => {
    switch (criteria) {
      case Criteria.ZIP_CITY_ESTATE_ID:
        return <ZipCityEstateIdFilter fieldName="zipCityEstateId" />;
      case Criteria.PERIMETER:
        return <PerimeterFilter fieldName="perimeter" />;
      case Criteria.MARKETING_TYPE_OBJECT_TYPE:
        return (
          <MarketingTypeObjectTypeFilter marketingTypeFieldName="marketingType" objectTypeFieldName="objectType" />
        );
      case Criteria.PRICE:
        return (
          <FromToFilter
            headline={t('price')}
            labelFrom={t('from')}
            fieldNameFrom="minPrice"
            labelTo={t('until')}
            fieldNameTo="maxPrice"
            replaceRegex={/[^0-9]/g}
          />
        );
      case Criteria.MARKET_VALUE:
        return (
          <FromToFilter
            headline={t('price')}
            labelFrom={t('from')}
            fieldNameFrom="minMarketValue"
            labelTo={t('until')}
            fieldNameTo="maxMarketValue"
            replaceRegex={/[^0-9]/g}
          />
        );
      case Criteria.RENT:
        return (
          <FromToFilter
            headline={t('price')}
            labelFrom={t('from')}
            fieldNameFrom="minRent"
            labelTo={t('until')}
            fieldNameTo="maxRent"
            replaceRegex={/[^0-9]/g}
          />
        );
      case Criteria.LEASE:
        return (
          <FromToFilter
            headline={t('price')}
            labelFrom={t('from')}
            fieldNameFrom="minLease"
            labelTo={t('until')}
            fieldNameTo="maxLease"
            replaceRegex={/[^0-9]/g}
          />
        );
      case Criteria.LIVING_SPACE:
        return (
          <FromToFilter
            headline={t('livingSpace')}
            labelFrom={t('from')}
            fieldNameFrom="minLivingSpace"
            labelTo={t('until')}
            fieldNameTo="maxLivingSpace"
          />
        );
      case Criteria.PROPERTY_SIZE:
        return (
          <FromToFilter
            headline={t('propertySize')}
            labelFrom={t('from')}
            fieldNameFrom="minPropertySize"
            labelTo={t('until')}
            fieldNameTo="maxPropertySize"
          />
        );
      case Criteria.TOTAL_SPACE:
        return (
          <FromToFilter
            headline={t('totalSpace')}
            labelFrom={t('from')}
            fieldNameFrom="minTotalSpace"
            labelTo={t('until')}
            fieldNameTo="maxTotalSpace"
          />
        );
      case Criteria.ROOMS:
        return (
          <FromToFilter
            headline="Zimmer"
            labelFrom={t('from')}
            fieldNameFrom="minNumberRooms"
            labelTo={t('until')}
            fieldNameTo="maxNumberRooms"
          />
        );
      default:
        return <AllFilters filterValues={filterValues} changeCriteria={changeCriteria} />;
    }
  };

  const renderFeatures = () => {
    if (showAllFilter && criteria === null) {
      return <FeatureFilter filterValues={filterValues} />;
    }

    return null;
  };

  return (
    <Drawer
      data-id="estate-result-filter-drawer"
      anchor="right"
      open={visible}
      onClose={close}
      PaperProps={{
        sx: { width: { xs: '100%', md: '370px', text: '400px', lg: '479px' } },
      }}
    >
      <Box sx={{ textAlign: 'right' }}>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>
      {visible && (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1, overflow: 'hidden' }}>
          <Box
            sx={{
              flexGrow: 1,
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {renderSort()}
            {renderFilter()}
            {renderFeatures()}
          </Box>
          <Box>
            <Divider />
            <Box sx={{ mx: 6, my: 5 }}>
              <Grid container columnSpacing={5}>
                <Buttons criteria={criteria} handleCancel={handleCancel} close={close} />
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

FilterDrawer.displayName = 'FilterDrawer';
