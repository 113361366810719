import { alpha, Box, Paper, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { InfoCircleOutline } from '@portals/icons';
import React from 'react';

interface Props {
  icon?: JSX.Element;
  headline: string;
  text: string;
  buttonLabel?: string;
  onClick?: () => void;
  buttonIcon?: React.ReactElement;
  isSaveSearchButtonDisabled?: boolean;
}

export const NotificationActionCard = ({
  icon = <InfoCircleOutline fontSize="inherit" />,
  headline,
  text,
  buttonLabel,
  onClick,
  buttonIcon,
  isSaveSearchButtonDisabled,
}: Props): React.ReactElement => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        width: '100%',
        boxShadow: `0px 2px 3px ${alpha(theme.palette.secondary['main'], 0.1)}`,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey['300'],
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 7 }}>
        <Box sx={{ fontSize: '42px', lineHeight: '1px', pb: 6 }}>{icon}</Box>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {headline}
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
          {text}
        </Typography>
        {buttonLabel && (
          <Box sx={{ mt: 6 }}>
            <Button onClick={onClick} disabled={isSaveSearchButtonDisabled}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ pr: 2 }}>{buttonLabel}</Box>
                {buttonIcon}
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

NotificationActionCard.displayName = 'NotificationActionCard';
