import { SxProps } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): Record<string, Record<string, SxProps<Theme>>> => ({
  financeLoanAmount: {
    container: {
      padding: 'none',
    },
    priceTypography: { fontWeight: 'bold' },
    coloredPriceTypography: { fontWeight: 'bold', color: theme.palette.green.main },
    coloredTypography: { color: theme.palette.green.main },
    hintContainer: { display: 'flex', alignItems: 'center', gap: 3 },
  },
  financeConditions: {
    calculateButtonContainer: {
      textAlign: 'center',
    },
    container: {
      padding: 'none',
    },
    labelTypography: {
      textAlign: 'center',
    },
    dateTypography: {
      textAlign: { xs: 'center', sm: 'right' },
    },
    tableCellCenter: {
      textAlign: 'center',
    },
    tableCellRight: {
      textAlign: 'right',
    },
    coloredPriceTypography: { fontWeight: 'bold', color: theme.palette.green.main },
  },
  table: {
    container: {
      '& .calculate-conditions-button': { width: { xs: '100%', lg: 'inherit' } },
      '& .table-container': {
        gap: {
          xs: 5,
          lg: 6,
        },
        display: 'flex',
        flexDirection: 'column',
      },
      '& table': { tableLayout: 'fixed' },
      '& thead > tr': {
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
      },
      '& tbody > tr': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      '& tfoot > tr': {
        borderTop: `1px solid ${theme.palette.grey[500]}`,
      },
      '& tbody:not(:last-child) > tr:last-child': {
        borderBottom: 0,
      },
      '& th': {
        py: { xs: 4, lg: 6 },
        verticalAlign: 'text-top',
        border: 0,
        px: { lg: 4 },
      },
      '& td': {
        py: { xs: 4, lg: 6 },
        fontWeight: { lg: 'bold' },
        border: 0,
        px: { lg: 4 },
      },
      '& td:first-of-type, th:first-of-type': {
        pl: { xs: 5, lg: 6 },
      },
      '& td:last-of-type, th:last-of-type': {
        pr: { xs: 5, lg: 6 },
      },
    },
  },
});
