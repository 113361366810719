import { Box } from '@mui/material';
import { Divider } from '@portals/core/src/components/Divider/Divider';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { EstateDetail as EstateDetailType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { filter, join, map } from 'lodash-es';
import React from 'react';

import { RequestFullAddressLink } from '../Shared/RequestFullAddressLink/RequestFullAddressLink';
import { SectionContainer } from '../Shared/SectionContainer/SectionContainer';
import { getStyles } from './FactBlock.styles';

type Props = {
  estate: EstateDetailType;
  requestContactDetails: () => void;
};

const t = getFixedT(null, 'core-immobilien');

export const FactBlock = ({ estate, requestContactDetails }: Props): JSX.Element => {
  const styles = getStyles();

  const filteredMainFacts = filter(estate.mainFacts, (mainFact) => {
    return mainFact.label !== 'Objektnummer';
  });

  let hasFullAddress = false;
  const addressArray = [estate.address.zip, estate.address.city];
  if (estate.address.street != null && estate.address.streetNumber != null) {
    addressArray.unshift(estate.address.street, estate.address.streetNumber);
    hasFullAddress = true;
  }

  filteredMainFacts.push({
    label: t('address'),
    value: join(addressArray, ' '),
    showRequestButton: !hasFullAddress,
  });

  return (
    <SectionContainer>
      <Divider sx={styles.divider} />
      <Box sx={styles.container}>
        {map(filteredMainFacts, (mainFact) => (
          <Box key={mainFact.label} sx={styles.mainFact}>
            <Typography component="h6" variant="body2" sx={styles.mainFactLabel} className="mainfact-label">
              {mainFact.label}
            </Typography>
            <Typography component="p" variant="h4" sx={styles.mainFactValue} className="mainfact-value">
              {mainFact.value}
            </Typography>
            {mainFact.showRequestButton && <RequestFullAddressLink onClick={requestContactDetails} />}
          </Box>
        ))}
      </Box>
      <Divider />
    </SectionContainer>
  );
};

FactBlock.displayName = 'FactBlock';
