import { SxProps } from '@mui/material';

import { theme } from '../../../themes/main';

export const styles: Record<string, SxProps> = {
  title: {
    width: '25ch',
    px: { xs: 5, lg: 6 },
    mb: { xs: 5, lg: 8 },
  },
  hitButton: {
    mt: { xs: 4, md: 0 },
    px: { xs: 5, md: 0 },
  },
  estateSearchSelectBox: {
    width: {
      sm: `calc(150px + ${theme.spacing(3)} / 2)`,
      lg: `calc(200px + ${theme.spacing(5)} / 2)`,
    },
    display: 'flex',
  },
  alignItemsStretch: {
    alignItems: 'stretch',
  },
};
