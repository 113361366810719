import Grid from '@mui/material/Grid2';
import { ServiceLink } from '@portals/core/src/components/ServiceLink/ServiceLink';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { MoreInformationEntry } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { map } from 'lodash-es';
import React from 'react';

type Props = {
  moreInformation: MoreInformationEntry[];
};

export const MoreInformationContent = ({ moreInformation }: Props): JSX.Element => {
  const moreInformationGridItems = map(moreInformation, (moreInformationEntry: MoreInformationEntry, key) => {
    return (
      <Grid size={12} key={key}>
        <Typography
          variant="body2"
          component="span"
          className="content-service-link"
          sx={{
            '@media print': {
              display: 'block',
              lineHeight: 1.75,
              fontSize: '13px',
              orphans: 3,
              widows: 3,
            },
          }}
        >
          <ServiceLink component="a" href={moreInformationEntry.url} target="_blank">
            {moreInformationEntry.anhangtitel}
          </ServiceLink>
        </Typography>
      </Grid>
    );
  });
  return (
    <Grid container sx={{ displayPrint: 'none', mt: { xs: 5, md: 6 } }}>
      {moreInformationGridItems}
    </Grid>
  );
};
MoreInformationContent.displayName = 'MoreInformationContent';
