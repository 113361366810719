import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { useNumberFormatter } from '@portals/forms/src/hooks/NumberFormatter/NumberFomatter';
import { getFixedT } from 'i18next';
import React, { FunctionComponent } from 'react';

import { LoansFinanceData } from '../../../../../types/loans';
import { getStyles } from './FinanceConditions.styles';

interface FinanceConditionsTableProps {
  loansData: LoansFinanceData;
}

const t = getFixedT(null, 'core-immobilien');

const FinanceConditionsTable: FunctionComponent<FinanceConditionsTableProps> = ({ loansData }) => {
  const styles = getStyles(theme).financeConditions;

  const numberFormatter = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <Table className="finance-conditions-table" sx={styles.container}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="h5">{t('detailPage.duration')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" sx={styles.labelTypography}>
              {t('detailPage.borrowingRate')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" sx={styles.labelTypography}>
              {t('detailPage.effectiveInterestRate')}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h5" sx={styles.dateTypography}>
              {t('detailPage.monthlyRate')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loansData.loansConditions.tenYears && (
          <TableRow key={`loans-${loansData.loansConditions.tenYears.years}`}>
            <TableCell>
              <Typography variant="button">{loansData.loansConditions.tenYears.years} Jahre</Typography>
            </TableCell>
            <TableCell sx={styles.tableCellCenter}>
              <Typography variant="button">
                {loansData.loansConditions.tenYears.interest.toLocaleString('de-DE')} % p.a.
              </Typography>
            </TableCell>
            <TableCell sx={styles.tableCellCenter}>
              <Typography variant="button">
                {loansData.loansConditions.tenYears.yearlyInterest.toLocaleString('de-DE')} % p.a.
              </Typography>
            </TableCell>
            <TableCell sx={styles.tableCellRight}>
              <Typography variant="button" sx={styles.coloredPriceTypography}>
                {numberFormatter(loansData.loansConditions.tenYears.monthlyPayment.toString())} €
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

FinanceConditionsTable.displayName = 'FinanceConditionsTable';

export default FinanceConditionsTable;
