import { Backdrop, BackdropProps, Box, Dialog, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '@portals/core/src/components/Button/Button';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Close, Home } from '@portals/icons';
import { CircleMarker } from '@portals/maps/src/components/CircleMarker/CircleMarker';
import { FitToBoundsHandler } from '@portals/maps/src/components/FitToBoundsHandler/FitToBoundsHandler';
import { Marker } from '@portals/maps/src/components/Marker/Marker';
import { Polygon } from '@portals/maps/src/components/Polygon/Polygon';
import { MapContainer } from '@portals/maps/src/containers/MapContainer/MapContainer';
import { Address } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { getFixedT } from 'i18next';
import { LatLngExpression } from 'leaflet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMapData } from '../../../../../hooks';
import { getStyles } from './EstateMapOverlay.styles';
import { clearSelectedMarkers, getActiveBranchLabel, renderIcon, selectMarkerByPoiId } from './EstateMapOverlay.utils';
import { POIControlPanel } from './POIControlPanel/POIControlPanel';

interface EstateMapOverlayProps {
  open: boolean;
  onClose: () => void;
  address: Address;
  lat: number;
  lng: number;
}

interface PointOfInterest {
  id: string;
  name: string;
  address: Address;
  lat: number;
  lng: number;
}

const emptyItem: PointOfInterest = {
  id: '',
  name: '',
  address: {
    street: '',
    streetNumber: '',
    city: '',
    zip: '',
  },
  lng: 0,
  lat: 0,
};

const t = getFixedT(null, 'core-immobilien');

export const EstateMapOverlay = ({ address, open, lng, lat, onClose }: EstateMapOverlayProps): React.ReactElement => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const { pointsOfInterestGroups, polygon, boundary } = useMapData(lat, lng, open);
  const [selectedGroup, setSelectedGroup] = useState(-1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerItem, setDrawerItem] = useState(emptyItem);
  const [isMapHandlerActive, setIsMapHandlerActive] = useState(true);
  const isBreakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isBreakpointLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const TILE_URL = ConfigProvider.getConfig().get('YELLOWMAP_TILE_URL');
  const streetText = address.street ? `${address.street} ${address.streetNumber}` : t('addressNotDisclosed');
  const addressText = `${streetText} | ${address.zip} ${address.city}`;

  const closeDrawer = useCallback(() => {
    setShowDrawer(false);
    clearSelectedMarkers();
  }, []);

  const showPoiInfoInDrawer = (poi: PointOfInterest) => {
    setShowDrawer(true);
    setDrawerItem(poi);
  };

  useEffect(() => {
    if (!open) {
      closeDrawer();
    }
  }, [open]);

  const handleSelectGroup = useCallback((index: number) => {
    setIsMapHandlerActive(true);
    setSelectedGroup(index);
  }, []);

  const handlePoiMarkerClick = useCallback(
    (clickedPoi: PointOfInterest) => () => {
      setIsMapHandlerActive(false);
      if (isBreakpointSmDown) {
        showPoiInfoInDrawer(clickedPoi);
        return undefined;
      }

      clearSelectedMarkers();
      selectMarkerByPoiId(clickedPoi.id);
    },
    [isBreakpointSmDown]
  );

  const renderMapMarkers = useMemo(() => {
    if (selectedGroup === -1 || !pointsOfInterestGroups?.length) {
      return null;
    }

    const MarkerIcon = ({ label }) => {
      const Icon = renderIcon(label);

      if (Icon) {
        return <Icon />;
      }
      return undefined;
    };

    const MapMarker = styled(CircleMarker)(({ theme }) => ({
      border: '2px solid',
      borderColor: 'transparent',
      '&.Mui-selected': {
        borderColor: 'white',
      },
      '& .MuiSvgIcon-root': {
        width: '12px',
        fill: theme.palette.background.paper,
        [theme.breakpoints.up('lg')]: {
          width: '18px',
        },
      },
    }));

    return pointsOfInterestGroups[selectedGroup].items.map((poi: PointOfInterest) => {
      const popupText = `${poi.name}\n${poi.address.street} ${poi.address.streetNumber || ''}`;
      return (
        <MapMarker
          key={poi.id}
          radius={isBreakpointLgDown ? 12 : 18}
          radiusSelected={isBreakpointLgDown ? 12 : 18}
          position={[poi.lat, poi.lng]}
          popupText={!isBreakpointSmDown ? popupText : undefined}
          selected={isBreakpointSmDown && showDrawer && drawerItem.id === poi.id}
          className={poi.id}
          onClick={handlePoiMarkerClick(poi)}
        >
          <MarkerIcon label={getActiveBranchLabel(selectedGroup)} />
        </MapMarker>
      );
    });
  }, [selectedGroup, pointsOfInterestGroups, isBreakpointSmDown, showDrawer, drawerItem]);

  const DialogCloseButton = () => (
    <Box sx={styles.closeButtonContainer}>
      <Button
        variant="inverted"
        size="header"
        color="secondary"
        onClick={onClose}
        sx={styles.closeButton}
        endIcon={<Close sx={styles.closeIcon} />}
      >
        {t('close')}
      </Button>
      <IconButton onClick={onClose} circular sx={styles.closeIconButton}>
        <Close />
      </IconButton>
    </Box>
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation();
      closeDrawer();
    },
    [closeDrawer]
  );

  const CustomBackdrop = (props: BackdropProps) => <Backdrop {...props} onTouchStart={onTouchStart} />;

  return (
    <Dialog open={open} fullScreen sx={styles.dialog}>
      <Box sx={styles.dialogHeader}>
        <DialogCloseButton />
        <Typography variant="h3">{t('areaMap')}</Typography>
        <Typography variant="body2">{addressText}</Typography>
      </Box>
      <Box sx={styles.dialogContent}>
        <MapContainer
          className="estate-map-overlay"
          zoom={14}
          minZoom={7}
          maxZoom={18}
          containerProps={{ sx: styles.mapWrapper, width: '100%' }}
          style={{ width: '100%', height: '100%' }}
          center={[lat, lng]}
          url={TILE_URL}
          scrollWheelZoom={false}
        >
          {address.street && (
            <Marker position={[lat, lng]}>
              <Home fontSize="inherit" />
            </Marker>
          )}
          {!address.street && polygon && (
            <>
              {isMapHandlerActive && <FitToBoundsHandler coordinates={boundary} />}
              {polygon.map((poly: LatLngExpression[], index: number) => (
                <Polygon
                  key={index}
                  positions={poly}
                  fillColor="#FF8F00"
                  fillOpacity={0.2}
                  color="#FF8F00"
                  weight={2}
                />
              ))}
            </>
          )}
          {renderMapMarkers}
        </MapContainer>
      </Box>
      <Box sx={styles.dialogFooter}>
        <POIControlPanel onSelect={handleSelectGroup} selectedIndex={selectedGroup} />
      </Box>
      <Drawer
        open={showDrawer}
        anchor="bottom"
        onClose={closeDrawer}
        sx={{
          touchAction: 'pan-x pan-y',
          zIndex: 1300,
          '& .MuiDrawer-paper': { px: 8, pt: 6, pb: 9 },
        }}
        slots={{
          backdrop: CustomBackdrop,
        }}
      >
        <IconButton onClick={closeDrawer} sx={{ ml: 'auto', p: '2px', mb: 5, color: theme.palette.text.primary }}>
          <Close sx={{ width: '18px', height: '18px' }} />
        </IconButton>
        {drawerItem.address && (
          <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography variant="h5">{drawerItem.name}</Typography>
            <Typography variant="h6" color="inherit">
              {drawerItem.address?.street}
              {(drawerItem.address?.zip || drawerItem.address?.city) && (
                <Typography variant="inherit" component="span" sx={{ display: 'block' }}>
                  {drawerItem.address?.zip} {drawerItem.address?.city}
                </Typography>
              )}
            </Typography>
          </Box>
        )}
      </Drawer>
    </Dialog>
  );
};

EstateMapOverlay.displayName = 'EstateMapOverlay';
