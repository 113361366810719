import { Box, SxProps, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { map } from 'lodash-es';
import React from 'react';
import striptags from 'striptags';

interface LabelValue {
  label: string;
  value: string | number;
}

interface Props {
  data: LabelValue[];
}

export const ContentAttributeTable: React.FunctionComponent<Props> = ({ data }: Props) => {
  const theme = useTheme();

  const styles: Record<string, SxProps> = {
    boxWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      p: { xs: 5, lg: 6 },
      ':first-of-type': { pt: 2 },
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      gap: { xs: 4 },
      '@media print': { p: theme.spacing(3) },
    },
    typoPrint: {
      '@media print': { fontSize: '13px' },
    },
    button: {
      fontWeight: 'bold',
      textAlign: 'right',
    },
  };

  return (
    <>
      {map(data, (labelValuePair) => (
        <Box key={`${labelValuePair.label}_${labelValuePair.value}`} sx={styles.boxWrapper}>
          <Typography
            className="content-attribute-table-label"
            variant="body2"
            sx={{ ...styles.typoPrint, width: '50%' } as SxProps}
          >
            {labelValuePair.label}
          </Typography>
          <Typography
            className="content-attribute-table-value"
            variant="button"
            sx={{ ...styles.typoPrint, ...styles.button, width: '50%', fontWeight: 'bold' } as SxProps}
            dangerouslySetInnerHTML={{ __html: striptags(`${labelValuePair.value}`, ['a']) }}
          ></Typography>
        </Box>
      ))}
    </>
  );
};

ContentAttributeTable.displayName = 'ContentAttributeTable';
