import type { SxProps, Theme } from '@portals/core/src/themes/themes';

export const getStyles = (
  theme: Theme,
  positionLeftInPercent: number,
  positionRightInPercent: number
): { [key: string]: SxProps } => {
  const container = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: `1px solid ${theme.palette.secondary.dark}`,
    ':first-of-type': { borderLeft: 0 },
    left: `${positionLeftInPercent}%`,
    right: `${positionRightInPercent}%`,
    width: `${(positionRightInPercent - positionLeftInPercent).toFixed(2)}%`,
  };

  const label = {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexShrink: 0,
    height: 1,
    textAlign: 'center',
    printColorAdjust: 'exact',
  };

  const highlighted = {
    color: theme.palette.secondary.dark,
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexShrink: 0,
    height: '16px',
    width: '16px',
    [theme.breakpoints.up('lg')]: {
      height: '26px',
      width: '26px',
    },
    '+ div': {
      color: theme.palette.secondary.contrastText,
    },
  };

  return {
    container,
    label,
    highlighted,
  };
};
