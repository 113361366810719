import { Box, useTheme } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import type { Theme } from '@portals/core/src/themes/themes';
import { AlertCircleOutline } from '@portals/icons/src/AlertCircleOutline/AlertCircleOutline';
import React from 'react';

import { CenteredContainer } from '../../../../components';

type BoxProps = React.ComponentProps<typeof Box> & {
  headline: string;
  subline: string;
};

export const NotFoundBox = ({ headline, subline, children, sx, ...rest }: BoxProps): JSX.Element => {
  const theme = useTheme<Theme>();

  return (
    <CenteredContainer>
      <Box sx={{ textAlign: 'center', ...sx }} {...rest}>
        <AlertCircleOutline
          sx={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color: theme.palette.grey.light,
            display: 'block',
            margin: `0 auto ${theme.spacing(8)} auto`,
            width: { xs: theme.spacing(6), lg: theme.spacing(9) },
            height: { xs: theme.spacing(6), lg: theme.spacing(9) },
          }}
        />
        <Typography variant="h4">{headline}</Typography>
        <Typography variant="body2" sx={{ mb: 4, color: theme.palette.action.hint }}>
          {subline}
        </Typography>
        {children}
      </Box>
    </CenteredContainer>
  );
};

NotFoundBox.displayName = 'NotFoundBox';
