import { Box, Typography } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { ArrowRight } from '@portals/icons';
import { getFixedT } from 'i18next';
import React, { FunctionComponent } from 'react';

import { getExternalFinanceCertificateUrl } from '../../../../../utils/financeCertificateUtils';
import InfoSection from '../InfoSection/InfoSection';

interface FinanceCertificateProps {
  bankCode: string;
}

const t = getFixedT(null, 'core-immobilien');

const FinanceCertificate: FunctionComponent<FinanceCertificateProps> = ({ bankCode }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      <Box sx={{ bgcolor: 'red', borderRadius: 2, p: 9 }}>
        <Typography variant="h4" sx={{ color: '#FFFFFF' }}>
          {t('detailPage.financeCertificate')}
        </Typography>
        <Typography variant="body2" sx={{ color: '#BEBEBE', mb: 9 }}>
          {t('detailPage.financeCertificateDescription')}
        </Typography>
        <Button fullWidth endIcon={<ArrowRight />} component="a" href={getExternalFinanceCertificateUrl(bankCode)}>
          {t('detailPage.financeButtonLabel')}
        </Button>
      </Box>
      <InfoSection>{t('detailPage.financeText')}</InfoSection>
    </Box>
  );
};

FinanceCertificate.displayName = 'FinanceCertificate';

export default FinanceCertificate;
