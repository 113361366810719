import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import React, { FunctionComponent } from 'react';

import { Info } from '../../../../../icons';

const InfoSection: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ pr: { xs: 5, lg: 6 } }}>
        <Info fontSize="inherit" />
      </Box>
      <Typography variant="body2" className="finance-info-section">
        {children}
      </Typography>
    </Box>
  );
};

InfoSection.displayName = 'InfoSection';

export default InfoSection;
