import { Box, Checkbox, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  params: any;
  label: string;
  state: boolean;
}

const AutocompleteListItem: React.FC<Props> = ({ params, label, state }: Props) => {
  const theme = useTheme();
  return (
    <Box component="li">
      <Box {...params} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          component="span"
          sx={{ width: '100%', color: theme.palette.text.primary, pb: 0 }}
          className="autocomplete-list-item-text"
        >
          {label}
        </Typography>
        <Checkbox color="secondary" checked={state} />
      </Box>
      <Divider />
    </Box>
  );
};

AutocompleteListItem.displayName = 'AutocompleteListItem';

export default AutocompleteListItem;
