import { getLabelsFromZipCityEstateId } from '@portals/sip-client-data/src/general/ApiClientMapping/ApiClientMapping';
import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import { isArray } from 'lodash-es';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useContext } from 'react';
import { useLocalstorageState } from 'rooks';
import { v4 as uuidv4 } from 'uuid';

import { type AfterRegistrationAction, UserContext } from '../../components';
import { loginConfig } from '../../context';
import { useIsLoggedIn } from '../../hooks';
import {
  addSavedSearch,
  apiFormattedParamSetsAreEqual,
  convertSearchParamsToQuery,
  fetchSavedSearches,
  removeSavedSearch,
  SearchAgentsList,
} from '../../utils';

export type UseSearchIsSaved = {
  saveButtonLabelFormatter: (EstateSearchProps) => string;
  toggleSearchIsSaved: (EstateSearchProps) => void;
  loadSavedSearches: () => void;
  savedSearches: Array<{ id: number; params: ParsedUrlQuery }>;
};

const t = getFixedT(null, 'core-immobilien');

export const useSearchIsSaved = (): UseSearchIsSaved => {
  const [isLoggedIn] = useIsLoggedIn();
  const userContext = useContext(UserContext);
  const [savedSearches, setSavedSearches] = useLocalstorageState('saved-searches', null);

  const getSavedSearchId = useCallback(
    (searchParams: EstateSearchProps) => {
      const ignoreFields = ['sort_by'];

      if (isLoggedIn && savedSearches !== null) {
        const convertedSearchParams = convertSearchParamsForApiRequest(searchParams);
        for (const savedSearch of savedSearches) {
          if (apiFormattedParamSetsAreEqual(savedSearch.params, convertedSearchParams, ignoreFields)) {
            return savedSearch.id;
          }
        }
      }

      return null;
    },
    [isLoggedIn, savedSearches]
  );

  const toggleSearchIsSaved = async (searchParams: EstateSearchProps) => {
    if (!isLoggedIn) {
      const convertedParams = convertSearchParamsForApiRequest(searchParams);
      const afterRegistrationAction: AfterRegistrationAction = {
        type: 'save_search',
        data: convertedParams,
        hash: uuidv4(),
      };

      userContext.doLogin(
        async () => {
          const savedSearchList = await addSavedSearch(loginConfig.userApiUrl, convertedParams);
          await setSavedSearchList(savedSearchList);
        },
        { afterRegistrationHash: afterRegistrationAction.hash },
        afterRegistrationAction
      );
    } else {
      let updatedList: SearchAgentsList | null;

      const savedSearchId = getSavedSearchId(searchParams);
      if (savedSearchId > 0) {
        updatedList = await removeSavedSearch(loginConfig.userApiUrl, savedSearchId);
      } else {
        updatedList = await addSavedSearch(loginConfig.userApiUrl, convertSearchParamsForApiRequest(searchParams));
      }

      if (updatedList !== null) {
        await setSavedSearchList(updatedList);
      } else {
        const updatedIsLogin = await userContext.checkForLoginStateUpdates();
        if (!updatedIsLogin) {
          userContext.doLogin(async () => await toggleSearchIsSaved(searchParams));
        }
      }
    }
  };

  const loadSavedSearches = async () => {
    const data = await fetchSavedSearches(loginConfig.userApiUrl);
    if (data !== null) {
      await setSavedSearchList(data);
    }
  };

  const setSavedSearchList = (data: SearchAgentsList) => {
    const updatedSearches = [];
    for (const row of data) {
      updatedSearches.push({
        id: row.id,
        params: splitMarketingUsageObjectType(row.search_params),
      });
    }
    setSavedSearches(updatedSearches);
  };

  const saveButtonLabelFormatter = useCallback(
    (searchParams: EstateSearchProps) => {
      const savedSearchId = getSavedSearchId(searchParams);

      if (savedSearchId > 0) {
        return t('savedSearch');
      }

      return t('saveSearch');
    },
    [getSavedSearchId]
  );

  return {
    saveButtonLabelFormatter,
    toggleSearchIsSaved,
    loadSavedSearches,
    savedSearches,
  };
};

function convertSearchParamsForApiRequest(searchParams: EstateSearchProps): ParsedUrlQuery {
  if (searchParams.zipCityEstateId) {
    searchParams.zipCityEstateId = getLabelsFromZipCityEstateId(searchParams.zipCityEstateId);
  }

  return convertArrayFieldsInSearchParams(convertSearchParamsToQuery(searchParams));
}

function convertArrayFieldsInSearchParams(searchParams: EstateSearchProps): ParsedUrlQuery {
  const convertedSearchParams = {};

  for (const field in searchParams) {
    let value = searchParams[field];
    if (field.includes('[]') && !isArray(value)) {
      value = [value as string];
    }
    convertedSearchParams[field.replace('[]', '')] = value;
  }

  return convertedSearchParams;
}

//ToDo: Auf API heben wenn AEM abgelöst ist?
function splitMarketingUsageObjectType(params: ParsedUrlQuery): ParsedUrlQuery {
  if (params.marketing_usage_object_type) {
    [params.marketing_type, params.usage_type, params.object_type] = (
      params.marketing_usage_object_type as string
    ).split('_');
    delete params.marketing_usage_object_type;
  }
  return params;
}
