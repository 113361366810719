import { Box, SxProps, Table, TableBody, TableCell, TableFooter, TableRow } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import { useNumberFormatter } from '@portals/forms/src/hooks/NumberFormatter/NumberFomatter';
import { EstateDetail } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { getFixedT } from 'i18next';
import React, { FunctionComponent } from 'react';

import { LoansFinanceData } from '../../../../../types/loans';
import { getStyles } from './FinanceConditions.styles';
import FinanceConditionsTooltipButton from './FinanceConditionsTooltipButton';

interface FinanceLoanAmountTableProps {
  estate: EstateDetail;
  loansData: LoansFinanceData;
}

const t = getFixedT(null, 'core-immobilien');

const FinanceLoanAmountTable: FunctionComponent<FinanceLoanAmountTableProps> = ({ estate, loansData }) => {
  const numberFormatter = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const financeStyles = getStyles(theme);
  const tableStyles = financeStyles.table;
  const styles = financeStyles.financeLoanAmount;

  return (
    <Table className="finance-conditions-table" sx={{ ...tableStyles.container, ...styles.container } as SxProps}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body2" className="finance-block-text">
              {t('detailPage.purchasePrice')}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" sx={styles.priceTypography} className="finance-block-text">
              {numberFormatter(estate.priceNumeric.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box sx={styles.hintContainer}>
              <Typography variant="body2" className="finance-block-text">
                {t('detailPage.additionalPurchaseCosts')}
              </Typography>
              <FinanceConditionsTooltipButton text="detailPage.additionalPurchaseCostsTooltipText" />
            </Box>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" sx={styles.priceTypography} className="finance-block-text">
              + {numberFormatter(loansData.additionalCosts.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Box sx={styles.hintContainer}>
              <Typography variant="body2" className="finance-block-text">
                {t('detailPage.equityCapital')}
              </Typography>
              <FinanceConditionsTooltipButton text="detailPage.equityCapitalTooltipText" />
            </Box>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" sx={styles.priceTypography} className="finance-block-text">
              - {numberFormatter(loansData.downPayment.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>
            <Typography variant="body2" sx={styles.coloredTypography}>
              {t('detailPage.loanAmount')}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="button" sx={styles.coloredPriceTypography}>
              {numberFormatter(loansData.loanAmount.toString())} €
            </Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

FinanceLoanAmountTable.displayName = 'FinanceLoanAmountTable';

export default FinanceLoanAmountTable;
