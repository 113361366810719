import { Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';

export const getStyles = (theme: Theme): Record<string, Record<string, SxProps<Theme>>> => ({
  brokerContactDrawer: {
    contactBottomOuterContainer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      bgcolor: theme.palette.background.default,
      boxShadow: '0px -2px 3px rgba(0, 0, 0, 0.1)',
      py: 5,
      px: { xs: 5, sm: 8, md: 0 },
      zIndex: 2,
    },
    contactBottomInnerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    avatarCardContainer: {
      display: { xs: 'none', sm: 'inherit' },
      gap: { xs: 3, sm: 5 },
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      width: { xs: '100%', sm: 'inherit' },
      gap: 3,
    },
    button: {
      width: { xs: '100%', sm: 'inherit' },
    },
    drawerPaper: {
      width: { xs: '100%', md: '400px', lg: '479px' },
    },
    drawerContent: {
      px: { xs: 5, md: 6, lg: 6 },
      py: 6,
    },
    closeContainer: {
      textAlign: 'right',
    },
  },

  brokerAvatarCard: {
    standardAvatarContainer: {
      alignSelf: 'center',
      mr: 5,
    },
    compactAvatarContainer: {
      alignSelf: 'center',
      mr: 0,
    },
    standardAvatarSize: {
      width: 90,
      height: 90,
    },
    compactAvatarSize: {
      width: { xs: 42, lg: 62 },
      height: { xs: 42, lg: 62 },
    },
    standardTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: { xs: 3, sm: 4, lg: 3 },
    },
    compactTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: { xs: 0 },
    },
    brokerTypography: {
      fontWeight: '400',
    },
    providerTypography: {
      color: 'secondary.dark',
    },
  },

  brokerContactWrapper: {
    outerContainer: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'space-between',
      gap: { xs: 5, sm: 6 },
    },
    avatarContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      minWidth: 250,
    },
  },
});
