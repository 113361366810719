import { Box, Grid2 as Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

import { styles } from '../EstateSearch.styles';

const skeletonStyles = {
  input: {
    height: { xs: 42.7, lg: 62 },
    width: '100%',
  },
  button: {
    height: { xs: 45, lg: 62 },
    width: '100%',
  },
  title: {
    height: { xs: 26, lg: 42 },
  },
};

export const EstateSearchSkeleton: React.FC = () => (
  <Box className="search-form">
    <Box sx={styles.title}>
      <Skeleton variant="text" sx={skeletonStyles.title} />
    </Box>
    <Grid container columns={15} rowSpacing={{ xs: 5, md: 5, lg: 7 }} columnSpacing={{ xs: 5, md: 3, lg: 5 }}>
      <Grid size={{ xs: 15, md: 11 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 5, sm: 0 }} sx={styles.alignItemsStretch}>
          <Skeleton variant="rounded" sx={skeletonStyles.input} />
          <Skeleton variant="rounded" sx={{ ...skeletonStyles.input, display: { sm: 'none' } }} />
        </Stack>
      </Grid>

      <Grid size={{ xs: 15, md: 4 }}>
        <Skeleton variant="rounded" sx={skeletonStyles.input} />
      </Grid>

      <Grid size={{ xs: 15, md: 11 }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 5, md: 3, lg: 5 }}>
          <Skeleton variant="rounded" sx={skeletonStyles.input} />
          <Skeleton variant="rounded" sx={skeletonStyles.input} />
          <Skeleton variant="rounded" sx={skeletonStyles.input} />
        </Stack>
      </Grid>

      <Grid size={{ xs: 15, md: 4 }} sx={styles.hitButton}>
        <Skeleton variant="rectangular" sx={{ ...skeletonStyles.button, borderRadius: '50px' }} />
      </Grid>
    </Grid>
  </Box>
);

EstateSearchSkeleton.displayName = 'EstateSearchSkeleton';
