import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CategoryLinkList } from '@portals/core/src/components/CategoryLinkList/CategoryLinkList';
import { ProductLink } from '@portals/core/src/components/ProductLink/ProductLink';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { ArrowRight, Home, Location } from '@portals/icons';
import { getFixedT } from 'i18next';
import React, { useContext, useMemo } from 'react';

import { Criteria, FilterConfigFormat, getPerimeterLabelFromOptionsByValue } from '../../../../../config';
import { CatalogsContext } from '../../../../../context';
import {
  useLocationFormatter,
  useMarketingTypeEstateTypeIdFormatter,
  usePriceFormatter,
  useRoomFormatter,
  useSquareMetersFormatter,
} from '../../../../../hooks';
import { Area, Currency, Perimeter, PropertySize, Room } from '../../../../../icons';
import { FilterValuesProps } from '../../../../../types';
import { FilterConfigCriteria, getFilterConfig } from '../../../../../utils/estateTypeIdUtils';

interface AllFiltersProps {
  filterValues: FilterValuesProps;
  changeCriteria: (criteria: Criteria) => void;
}

const filterConfig: FilterConfigCriteria = getFilterConfig(FilterConfigFormat.Criteria) as FilterConfigCriteria;

const t = getFixedT(null, 'core-immobilien');

export const AllFilters: React.FC<AllFiltersProps> = ({ filterValues, changeCriteria }: AllFiltersProps) => {
  const { radii: catalogsRadii } = useContext(CatalogsContext);

  const squareMetersFormatter = useSquareMetersFormatter();
  const roomFormatter = useRoomFormatter();
  const priceFormatter = usePriceFormatter();
  const locationFormatter = useLocationFormatter();
  const marketingTypeEstateTypeIdFormatter = useMarketingTypeEstateTypeIdFormatter();

  const showCriteriaFilter = (criteria) => {
    changeCriteria(criteria);
  };

  const singleFilterConfig = useMemo(
    () =>
      filterConfig?.find(
        (item) => item.marketingType === filterValues.marketingType && item.estateTypeId === filterValues.estateTypeId
      ),
    [filterConfig, filterValues.marketingType, filterValues.estateTypeId]
  );

  const showPriceFilter = useMemo(() => singleFilterConfig?.fields?.includes(Criteria.PRICE), [singleFilterConfig]);
  const showRentFilter = useMemo(() => singleFilterConfig?.fields?.includes(Criteria.RENT), [singleFilterConfig]);
  const showLeaseFilter = useMemo(() => singleFilterConfig?.fields?.includes(Criteria.LEASE), [singleFilterConfig]);
  const showLivingSpaceFilter = useMemo(
    () => singleFilterConfig?.fields?.includes(Criteria.LIVING_SPACE),
    [singleFilterConfig]
  );
  const showPropertySizeFilter = useMemo(
    () => singleFilterConfig?.fields?.includes(Criteria.PROPERTY_SIZE),
    [singleFilterConfig]
  );
  const showTotalSpaceFilter = useMemo(
    () => singleFilterConfig?.fields?.includes(Criteria.TOTAL_SPACE),
    [singleFilterConfig]
  );
  const showRoomsFilter = useMemo(() => singleFilterConfig?.fields?.includes(Criteria.ROOMS), [singleFilterConfig]);

  return (
    <Box sx={{ px: 6 }}>
      <Typography variant="h3">{t('customizeSearch')}</Typography>
      <Box>
        <Grid container size={12}>
          <CategoryLinkList>
            <ProductLink
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => {
                showCriteriaFilter(Criteria.ZIP_CITY_ESTATE_ID);
              }}
              startIcon={<Location />}
            >
              {locationFormatter(filterValues.zipCityEstateId, t('where'))}
            </ProductLink>
            {filterValues.perimeter !== null && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.PERIMETER);
                }}
                startIcon={<Perimeter />}
                endIcon={<ArrowRight />}
              >
                {getPerimeterLabelFromOptionsByValue(t, filterValues.perimeter, catalogsRadii)}
              </ProductLink>
            )}
            <ProductLink
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={() => {
                showCriteriaFilter(Criteria.MARKETING_TYPE_OBJECT_TYPE);
              }}
              startIcon={<Home />}
            >
              {marketingTypeEstateTypeIdFormatter(filterValues.marketingType, filterValues.estateTypeId, t('object'))}
            </ProductLink>
            {showPriceFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.PRICE);
                }}
                startIcon={<Currency />}
              >
                {priceFormatter(filterValues.minPrice, filterValues.maxPrice, t('price'))}
              </ProductLink>
            )}
            {showRentFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.RENT);
                }}
                startIcon={<Currency />}
              >
                {priceFormatter(filterValues.minRent, filterValues.maxRent, t('price'))}
              </ProductLink>
            )}
            {showLeaseFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.LEASE);
                }}
                startIcon={<Currency />}
              >
                {priceFormatter(filterValues.minLease, filterValues.maxLease, t('price'))}
              </ProductLink>
            )}
            {showLivingSpaceFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.LIVING_SPACE);
                }}
                startIcon={<Area />}
              >
                {squareMetersFormatter(filterValues.minLivingSpace, filterValues.maxLivingSpace, t('livingSpace'))}
              </ProductLink>
            )}
            {showPropertySizeFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.PROPERTY_SIZE);
                }}
                startIcon={<PropertySize />}
              >
                {squareMetersFormatter(filterValues.minPropertySize, filterValues.maxPropertySize, t('propertySize'))}
              </ProductLink>
            )}
            {showTotalSpaceFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.TOTAL_SPACE);
                }}
                startIcon={<Area />}
              >
                {squareMetersFormatter(filterValues.minTotalSpace, filterValues.maxTotalSpace, t('totalSpace'))}
              </ProductLink>
            )}
            {showRoomsFilter && (
              <ProductLink
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => {
                  showCriteriaFilter(Criteria.ROOMS);
                }}
                startIcon={<Room />}
              >
                {roomFormatter(filterValues.minNumberRooms, filterValues.maxNumberRooms, t('room'))}
              </ProductLink>
            )}
          </CategoryLinkList>
        </Grid>
      </Box>
    </Box>
  );
};

AllFilters.displayName = 'AllFilters';
